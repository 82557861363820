import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
	chipsContainer: {
		padding: spacing(1.5, 0),
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			margin: spacing(0.5),
			'& .chipIcon': {
				marginLeft: spacing(1)
			}
		}
	}
}));
