import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  breadCrumb: {
    display: 'flex',
    alignItems: 'center',
    '& *': {
      color: '#fff',
      fontSize: '2rem',
    },
    '& svg': {
      marginRight: spacing(0.5),
      fontSize: '2.2rem',
    },
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  branchContainer: {
    color: '#fff',
    margin: spacing(3, 0, 4),
  },
  list: {
    margin: spacing(1, 0),
  },
  listItem: {
    margin: spacing(0, 0, 3),
  },
  loader: {
    textAlign: 'center',
    '& > div': {
      margin: spacing(4, 2),
    },
  },
}));
