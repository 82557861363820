import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  page: {
    position: 'absolute',
    left: 0,
    right: 0,
    [breakpoints.up('sm')]: {
      left: 12,
      right: 12,
    },
    '&.page-enter': {
      opacity: 0,
      transform: 'scale(1.05)',
    },

    '&.page-enter-active': {
      opacity: 1,
      transform: 'scale(1)',
      transition: 'opacity 450ms, transform 450ms',
    },

    '&.page-exit': {
      opacity: 1,
      transform: 'scale(1)',
    },

    '&.page-exit-active': {
      opacity: 0,
      transform: 'scale(0.95)',
      transition: 'opacity 450ms, transform 450ms',
    },
  },
}));
