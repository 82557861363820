import React, { memo, useCallback, useState } from 'react';
import useStyles from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDropzone } from 'react-dropzone';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import PublishIcon from '@material-ui/icons/Publish';
import Typography from '@material-ui/core/Typography';
import FileChips from 'components/FileChips/FileChips';

function FileUpload({ attachedFiles, setAttachedFiles }) {
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });
  const [error, setError] = useState('');

  const rejectedError = 'Only images, videos and compressed (zip) files are allowed!';

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        setError(rejectedError);
        return;
      } else if (acceptedFiles.length + attachedFiles.length > 1) {
        setError('You can only select up to 1 files!');
        return;
      } else {
        setError('');
        setAttachedFiles(st => [
          ...st.filter(f => !acceptedFiles.map(file => file.name).includes(f.name)),
          ...acceptedFiles,
        ]);
      }
    },
    [setAttachedFiles, attachedFiles.length],
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*, video/*, zip,application/zip,application/x-zip,application/x-zip-compressed',
    onDrop,
    multiple: false,
  });

  const classes = useStyles({ isDragActive, isDragAccept, isDragReject });

  return (
    <div className={classes.root}>
      <Collapse in={!attachedFiles.length}>
        <div className={classes.dragDropContainer}>
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <PublishIcon />
            <Typography className={classes.dropzoneText}>
              {isDragReject
                ? rejectedError
                : isDragActive
                ? 'Drop the files here ...'
                : up600
                ? "Drag 'n' drop some files here, or click to select files"
                : 'Tap to select files'}
            </Typography>
          </div>
          <Collapse in={!!error}>
            <Alert severity="error" onClose={() => setError('')}>
              {error}
            </Alert>
          </Collapse>
        </div>
      </Collapse>

      <FileChips attachedFiles={attachedFiles} setAttachedFiles={setAttachedFiles} />
    </div>
  );
}

export default memo(FileUpload);
