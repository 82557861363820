/**
|--------------------------------------------------
| Function, nests an array of objects into 2 levels
| @param {Array} Array of objects to be nested
| @param {String} first level key
| @param {String} first level values
| @param {String} second level key
| @param {String} second level values
|--------------------------------------------------
*/

export default function nestArrayOfObjects(arr, key1, key1Values, key2, key2Values) {
	return [ ...new Set(arr.map(item => item[key1])) ].map(val => {
		const filtered = arr.filter(item => item[key1] === val);

		const nestedList = [ ...new Set(filtered.map(pr => pr[key2])) ].map(val => ({
			[key2]: val,
			[key2Values]: filtered.filter(item => item[key2] === val)
		}));

		return {
			[key1]: val,
			[key1Values]: key2 ? nestedList : filtered
		};
	});
}
