import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import AuthPage from 'pages/AuthPage/AuthPage';
import Dashbord from 'pages/Dashbord/Dashbord';
import BranchAds from 'pages/BranchAds/BranchAds';
import MyAds from 'pages/MyAds/MyAds';
import BranchScreens from 'pages/BranchScreens/BranchScreens';

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <AuthPage />
        </Route>
        <PrivateRoute path="/ads">
          <MyAds />
        </PrivateRoute>
        <PrivateRoute path="/branch/:slug/ads">
          <BranchAds />
        </PrivateRoute>
        <PrivateRoute path="/branch/:slug/screens">
          <BranchScreens />
        </PrivateRoute>
        <PrivateRoute exact path="/">
          <Dashbord />
        </PrivateRoute>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default Routes;
