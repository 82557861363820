import { put, call, takeLatest } from 'redux-saga/effects';
import { loadAds, receivedAds, deleteAd, deleteAdSuccess } from 'redux/features/ads.slice';
import { deleteBranchAdSuccess } from 'redux/features/branchAdsList.slice';
import API from 'utils/api';
import snackbar from 'utils/snackbar';
import * as Sentry from '@sentry/react';

const fetchAds = () => API.get('api/myads');
const deleteAdAPI = id =>
  API.delete('api/admin/ads/delete', {
    data: {
      id,
    },
  });

function* handleDeleteAd({ payload: { id, branchId } }) {
  try {
    const resp = yield call(deleteAdAPI, id);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    yield !!branchId ? put(deleteBranchAdSuccess(id)) : put(deleteAdSuccess(id));
    yield snackbar.toast(resp.data?.message);
  } catch (error) {
    yield Sentry.captureException(error, {
      extra: {
        error_response: error.response,
        arguments: arguments,
      },
    });
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('We were unable to delete your ad');
  }
}

function* handleLoadAds() {
  try {
    const resp = yield call(fetchAds);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    yield put(receivedAds({ list: resp.data, count: resp.data.length }));
  } catch (error) {
    yield Sentry.captureException(error, {
      extra: {
        error_response: error.response,
        arguments: arguments,
      },
    });
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('We were unable to fetch your ads');
  }
}

export default function* watchAds() {
  yield takeLatest(loadAds.toString(), handleLoadAds);
  yield takeLatest(deleteAd.toString(), handleDeleteAd);
}
