import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  tabs: {
    margin: spacing(-1.125, 'auto', 0),
    width: '100%',
    '& .MuiTabs-indicator': {
      backgroundColor: '#fff',
      borderRadius: 2,
      height: 3,
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-evenly',
      [breakpoints.up('sm')]: {
        justifyContent: 'center',
      },
    },
  },
  tab: {
    '&&&': {
      overflow: 'visible',
      margin: spacing(0, 1.5),
      minWidth: 'unset',
      padding: 0,
      [breakpoints.up('sm')]: {
        margin: spacing(0, 2.5),
      },
    },
    '&&': {
      '& .MuiBadge-root .MuiTab-wrapper': {
        zIndex: 3,
      },
      '& .MuiBadge-badge': {
        top: 5,
        right: -6,
      },
    },
  },
}));
