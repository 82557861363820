import { makeStyles } from '@material-ui/core/styles';
import man from 'assets/images/man.png';
import manRight from 'assets/images/man-right.png';
import screenLeft from 'assets/images/screen-left.png';
import screen from 'assets/images/screen.png';
import lightStroke from 'assets/images/shape-4-w.png';
import stroke from 'assets/images/shape-2-w.png';
import circle from 'assets/images/shape-3-w.png';
import happyman from 'assets/images/shape-happyman-w.png';

export default makeStyles(({ breakpoints, spacing }) => ({
  root: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    '& > *': {
      position: 'absolute',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      willChange: 'transform',
      fontSize: 12,
      left: spacing(-6),
      [breakpoints.up('md')]: {
        fontSize: 14,
        left: spacing(-3),
      },
      [breakpoints.up('lg')]: {
        fontSize: 16,
        left: 'unset',
      },
    },
  },
  man: {
    width: '10.2em',
    left: 'calc(50% - 7.5em)',
    [breakpoints.up('sm')]: {
      left: '7.2em',
    },
    [breakpoints.up('md')]: {
      left: '9.2em',
    },
    [breakpoints.up('lg')]: {
      left: '11em',
    },
    top: '-3.2em',
    height: '100%',
    backgroundImage: `url(${man})`,
  },
  screen: {
    width: '25em',
    [breakpoints.down('xs')]: {
      left: 'calc(50% - 18.5em)',
    },
    height: '100%',
    backgroundImage: `url(${screen})`,
  },
  manRight: {
    backgroundImage: `url(${manRight})`,
  },
  screenLeft: {
    backgroundImage: `url(${screenLeft})`,
  },
  lightStroke: {
    width: '12.5em',
    height: '100%',
    backgroundImage: `url(${lightStroke})`,
    opacity: 0.6,
  },
  stroke: {
    width: '12.5em',
    height: '100%',
    backgroundImage: `url(${stroke})`,
    opacity: 0.3,
  },
  strokeSmall: {
    width: '6.875em',
    height: '100%',
    backgroundImage: `url(${stroke})`,
    opacity: 0.3,
  },
  circle: {
    width: '6.25em',
    height: '100%',
    backgroundImage: `url(${circle})`,
    opacity: 0.3,
  },
  happyman: {
    width: '5em',
    height: '100%',
    backgroundImage: `url(${happyman})`,
    opacity: 0.21,
  },
}));
