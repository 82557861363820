import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  expPanel: {
    '&.Mui-disabled': {
      backgroundColor: 'unset',
    },
  },
  expPanelSummary: {
    '& .MuiIconButton-root': {
      [breakpoints.down('sm')]: {
        padding: 6,
      },
    },
    '&.Mui-disabled': {
      opacity: 'unset',
    },
    '&& > div': {
      margin: 0,
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  expPanelDetails: {
    flexDirection: 'column',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '&&': {
      padding: spacing(0, 2, 1.5),
    },
  },
  expandIcon: {
    opacity: ({ disabled }) => (disabled ? 0 : 1),
    fontSize: '2.6rem',
    color: palette.text.primary,
  },
  cardHeader: {
    padding: spacing(1.4, 1, 1.6),
    [breakpoints.up('md')]: {
      flex: 1,
    },
    '& .MuiCardHeader-subheader': {
      fontSize: '1.3rem',
    },
  },
  cardActions: {
    pointerEvents: 'visible',
    paddingInlineStart: spacing(1),
    padding: 0,
    color: palette.text.primary,
    '& .MuiIconButton-root': {
      color: 'inherit',
    },
    '& svg': {
      fontSize: '2.6rem',
      color: 'inherit',
    },
  },
  headerChips: {
    display: 'flex',
    '& > svg': {
      fontSize: '2.8rem',
    },
    [breakpoints.up('md')]: {
      flex: 0.8,
    },
    '& .MuiSvgIcon-root': {
      color: palette.primary.main,
    },
  },
  chip: {
    cursor: 'pointer',
    margin: spacing(0, 1),
    textTransform: 'capitalize',
  },
}));
