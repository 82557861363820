import React, { memo, useState } from 'react';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import MediaCard from 'components/MediaCard/MediaCard';
import MyAdsCard from 'components/MyAdsCard/MyAdsCard';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import api from 'utils/api';
import snackbar from 'utils/snackbar';
import PaidAdCard from 'components/PaidAdCard/PaidAdCard';
import CloseIcon from '@material-ui/icons/Close';
import useLazyLoading from 'utils/hooks/useLazyLoading';
import useStyles from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow {...props} ref={ref} />;
});

function ScreenCard({ title, orientation, last_activity, ads, id }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [adsList, setAdsList] = useState(null);
  const [selectedAd, setSelectAd] = useState(null);

  const { lastElementRef, itemsCount } = useLazyLoading({
    loading: loading,
    initialItemsCount: 10,
    increaseBy: 5,
    listLength: adsList?.length,
  });

  const chips = [
    { label: orientation },
    {
      label: (
        <>
          <b>{ads}</b> ads
        </>
      ),
    },
  ];

  const fetchAds = async () => {
    try {
      const { data } = await api.get(`/api/screens/ads?id=${id}`);
      process.env.NODE_ENV === 'development' && console.log(data);

      setAdsList(data.ads);
      setLoading(false);
    } catch (error) {
      console.log(error.response);
      console.log(error.toString());
      snackbar.error('We were unable to fetch your ads');
      setLoading(false);
    }
  };

  const onClose = () => setSelectAd(null);
  const onDeleteAd = () => {
    fetchAds();
    onClose();
  };

  const closeButton = {
    title: 'Close Dialog',
    icon: <CloseIcon />,
    onClick: onClose,
  };

  return (
    <>
      <Dialog
        open={!!selectedAd?.id}
        onClose={onClose}
        TransitionComponent={Transition}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        className={classes.dialog}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          {selectedAd?.personal ? (
            <MyAdsCard {...selectedAd} closeButton={closeButton} onDeleteAd={onDeleteAd} />
          ) : (
            <PaidAdCard {...selectedAd} closeButton={closeButton} onCloseDialog={onClose} />
          )}
        </DialogContent>
      </Dialog>
      <CardWrapper
        id={id}
        onClick={fetchAds}
        title={title}
        subheader={`Latest Activity: ${last_activity}`}
        chips={chips}
        disabled={!ads}
      >
        {ads && (
          <Box display="flex" flexWrap="wrap" className={classes.ads}>
            {loading &&
              [...Array(ads > 10 ? 10 : ads)].map((_, i) => (
                <Skeleton
                  key={i}
                  className={classes.skeleton}
                  animation="wave"
                  variant="rect"
                  height={170}
                />
              ))}
            {adsList?.slice(0, itemsCount).map((ad, i, arr) => (
              <div key={ad.id}>
                <MediaCard
                  className={classes.mediaCard}
                  url={ad.mediaUrl}
                  title={ad.adTitle}
                  mediaType={ad.mediaType}
                  showTitle
                  onClick={() => setSelectAd(ad)}
                />
                <div ref={i === arr.length - 1 ? lastElementRef : null} />
              </div>
            ))}
          </Box>
        )}
      </CardWrapper>
    </>
  );
}

export default memo(ScreenCard);
