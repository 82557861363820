import { put, call, takeLatest } from 'redux-saga/effects';
import { loadBranches, receivedBranches } from 'redux/features/branches.slice';
import snackbar from 'utils/snackbar';
import API from 'utils/api';

const fetchBranches = () => API.get('/api/branches/list');
// const fetchBranches = () => axios.get('./branchesList.json');

function* handleLoadBranches() {
  try {
    const resp = yield call(fetchBranches);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    yield put(receivedBranches({ list: resp.data, count: resp.data.length }));
  } catch (error) {
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('We were unable to fetch your branches');
  }
}

export default function* watchBranches() {
  yield takeLatest(loadBranches.toString(), handleLoadBranches);
}
