/**
|--------------------------------------------------
| Function, loops over object properties
| @param {Object} Object to be looped
| @param {Callback} loop call back function that will be executed on the object
|--------------------------------------------------
*/

export default function loopObject(object, loopCallback) {
  return Object.fromEntries(Object.entries(object).map(loopCallback));
}
