import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useSelector, useDispatch } from 'react-redux';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import useStyles from './styles';
import { logoutUser } from 'redux/features/auth.slice';
import { AdzilySymbol, AdzilyLogo } from 'assets/svg-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useHistory } from 'react-router-dom';
import Tabs from 'components/Tabs/Tabs';
import ProfilePopover from 'components/ProfilePopover/ProfilePopover';

function Navbar() {
  const dispatch = useDispatch();
  const [scrolled, SetScrolled] = useState(false);
  const [anchorProfile, setAnchorProfile] = useState(null);
  const classes = useStyles({ scrolled });
  const user = useSelector(state => state.auth.currentUser);
  const up900 = useMediaQuery('(min-width:900px)');
  const up600 = useMediaQuery('(min-width:600px)');
  const history = useHistory();

  const handleSignout = () => dispatch(logoutUser());
  const openPopover = e => setAnchorProfile(e.currentTarget);

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -220) {
      SetScrolled(true);
    } else {
      SetScrolled(false);
    }
  }, []);

  const { fullname, email, partner } = user;
  const partnerName = partner?.title;

  return (
    <>
      <Toolbar />
      {!up600 && <Toolbar />}
      <AppBar position="fixed" className={classes.root}>
        <div className={classes.flexContainer}>
          <div className={classes.flex}>
            <IconButton className={classes.logoButton} onClick={() => history.push('/')}>
              {up900 || !up600 ? (
                <AdzilyLogo className={classes.logo} />
              ) : (
                <AdzilySymbol className={classes.symbol} />
              )}
            </IconButton>
            <Toolbar className={classes.toolbar}>
              {up600 && <Tabs />}
              <Chip
                color="primary"
                className={classes.user}
                avatar={<Avatar>{partnerName?.charAt(0).toUpperCase()}</Avatar>}
                onClick={openPopover}
                label={partnerName}
                onDelete={openPopover}
                deleteIcon={<ArrowDropDownIcon />}
              />
            </Toolbar>
            <ProfilePopover
              username={fullname}
              email={email}
              anchorEl={anchorProfile}
              setAnchorEl={setAnchorProfile}
              onSignOut={handleSignout}
            />
          </div>

          {!up600 && (
            <Toolbar>
              <Tabs />
            </Toolbar>
          )}
        </div>
      </AppBar>
    </>
  );
}

export default Navbar;
