import React, { useEffect } from 'react';
import PageTransition from 'components/PageTransition/PageTransition';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import Fade from '@material-ui/core/Fade';
import { useParams, Redirect, Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { loadBranchAds, clearBranch } from 'redux/features/branchAdsList.slice';
import HomeIcon from '@material-ui/icons/Home';
import useLazyLoading from 'utils/hooks/useLazyLoading';
import MyAdsCard from 'components/MyAdsCard/MyAdsCard';

function BranchAds() {
  const classes = useStyles();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const branch = useSelector(state => state.branchAdsList);
  const { lastElementRef, itemsCount } = useLazyLoading({
    loading: branch.loading,
    initialItemsCount: 4,
    increaseBy: 1,
    listLength: branch.myAdsList?.length,
  });

  useEffect(() => {
    dispatch(loadBranchAds(slug));
    return () => {
      dispatch(clearBranch());
    };
  }, [dispatch, slug]);

  const { province, area, city } = branch;
  process.env.NODE_ENV === 'development' && console.log(branch);

  if (!branch.loading && !branch.id) {
    return <Redirect to="/" />;
  }
  return (
    <PageTransition>
      <div>
        <div className={classes.branchContainer}>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
            <MuiLink color="inherit" component={Link} to="/">
              <HomeIcon />
              Branches
            </MuiLink>
            <Typography color="textPrimary">{!branch.loading && branch?.branch}</Typography>
          </Breadcrumbs>
          <Typography>{!branch.loading && `${area ? area : province}, ${city}`}</Typography>
        </div>
        <Fade in={!branch.loading} mountOnEnter unmountOnExit timeout={400}>
          <div className={classes.list}>
            {branch.myAdsList?.slice(0, itemsCount).map((ad, i, arr) => {
              return (
                <div
                  key={ad.id}
                  className={classes.listItem}
                  ref={i === arr.length - 1 ? lastElementRef : null}
                >
                  <MyAdsCard {...ad} personal branchId={slug} />
                </div>
              );
            })}
          </div>
        </Fade>
      </div>
    </PageTransition>
  );
}

export default BranchAds;
