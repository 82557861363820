import React, { memo } from 'react';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import useStyles from './styles';

function SearchFilter({
  value,
  onChange,
  setValue,
  tooltipTitle,
  searchOpen,
  setSearchOpen,
  className,
}) {
  const classes = useStyles({ searchOpen });

  return (
    <ClickAwayListener onClickAway={() => !value && setSearchOpen(false)}>
      <Tooltip title={tooltipTitle} placement="top" arrow>
        <Paper className={clsx(classes.root, className)}>
          <Button
            className={classes.searchButton}
            disabled={searchOpen}
            aria-label="clear search"
            onClick={() => setSearchOpen(true)}
          >
            <SearchIcon />
          </Button>
          <TextField
            className={classes.textField}
            size="small"
            variant="outlined"
            id="search-ads"
            label="Search ads"
            autoComplete="off"
            value={value}
            onChange={onChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={!value}
                    aria-label="clear search"
                    onClick={() => setValue('')}
                    onMouseDown={e => e.preventDefault()}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Paper>
      </Tooltip>
    </ClickAwayListener>
  );
}

export default memo(SearchFilter);
