import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  listItemText: {
    '& *': {
      fontWeight: 500,
    },
  },
  avatar: {
    backgroundColor: 'transparent',
    color: palette.text.primary,
  },
  selectButton: {
    marginLeft: spacing(1),
  },
  listItem: {
    '& > div': {
      margin: spacing(0.75, 0),
    },
  },
}));
