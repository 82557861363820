import { put, call, select, takeLatest, fork, take, putResolve } from 'redux-saga/effects';
import { eventChannel, END } from 'redux-saga';
import {
  loadAdCreation,
  receivedAdCreationBranches,
  toggleSelect,
  selectAll,
  selectItem,
  calcStats,
  submitAd,
  setDialogOpen,
  uploadStart,
  uploadFinish,
  setProgress,
  cancelUpload,
  receivedAd,
  reset,
  duplicateAd,
} from 'redux/features/adCreation.slice';
import axios from 'axios';
import snackbar from 'utils/snackbar';
import API from 'utils/api';
import { loadAds } from 'redux/features/ads.slice';
import moment from 'moment';
import differenceInWeeks from 'date-fns/differenceInWeeks';

/* --- api calls --- */

const fetchAdCreationItems = () => API.get(`/api/admin/ads/add`);
// const fetchAd = id => API.get(`/api/ads/edit/${id}`);

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
function upload(newAd, onProgress) {
  return API.post(`/api/admin/ads/add`, newAd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: source.token,
    onUploadProgress: onProgress,
  });
}

/* --- State getters --- */
const getAdCreation = state => state.adCreation;

/* --- helper functions --- */
const getSelectedScreens = branches =>
  branches.reduce(
    (acc, obj) =>
      acc.concat(
        obj.screensCollection.reduce((screens, currScreen) => {
          if (currScreen.selected) screens.push(currScreen.id);
          return screens;
        }, []),
      ),
    [],
  );

const getSelectedBranchesCount = branches =>
  branches.filter(br => br.screensCollection.some(screen => screen.selected)).length;

function createUploader(payload) {
  let emit;
  const channel = eventChannel(emitter => {
    emit = emitter;
    return () => {};
  });

  const uploadPromise = upload(payload, event => {
    const progress = parseInt(Math.round((event.loaded * 100) / event.total));
    emit(progress);
    if (progress >= 100) {
      emit(END);
    }
  });

  return [uploadPromise, channel];
}

/* --- Generators --- */
function* watchOnProgress(channel) {
  while (true) {
    const progress = yield take(channel);
    yield process.env.NODE_ENV === 'development' && console.log(progress);
    yield put(setProgress(progress));
  }
}

function* handleDuplicateAd({
  payload: { adTitle, campaign, startDate, endDate, duration, selectedScreens },
}) {
  try {
    yield put(reset());
    yield put(
      receivedAd({
        fields: {
          adName: `${adTitle} - Copy`,
          campaignName: campaign,
          runFor: `${differenceInWeeks(new Date(endDate), new Date(startDate))}`,
          adLength: `${duration}`,
        },
        adStartDate: new Date(),
      }),
    );
    const branchesResp = yield call(fetchAdCreationItems);
    yield process.env.NODE_ENV === 'development' && console.log(branchesResp);

    yield putResolve(setDialogOpen(true));

    const branches = yield branchesResp.data?.data?.map(item => ({
      ...item,
      screensCollection: item.screensCollection?.map(screen => ({
        ...screen,
        selected: selectedScreens.includes(screen.id),
      })),
    }));

    yield putResolve(
      receivedAdCreationBranches({
        branches,
      }),
    );

    const branchesCount = yield call(getSelectedBranchesCount, branches);

    // calculate the reach based on the selected options
    yield put(calcStats({ screensCount: selectedScreens.length, branchesCount }));
  } catch (error) {
    yield console.log(error.toString());
    yield snackbar.error('Oops, Something went wrong');
  }
}

function* handleLoadAdCreation({ payload: { selectedScreens } = {} }) {
  try {
    // Edit existing ad
    // if (adID) {
    // const fetchAdResp = yield call(fetchAd, adID);
    // yield process.env.NODE_ENV === 'development' && console.log(fetchAdResp);

    // const {
    //   fileName,
    //   mediaType,
    //   adStartDate,
    //   campaignName,
    //   adName,
    //   adLength,
    //   runFor,
    //   fileSize,
    //   selectedBranches,
    // } = yield fetchAdResp.data.data;

    // selectedBranchesID = yield JSON.parse(selectedBranches)?.map(br => br.id);

    // yield putResolve(
    //   receivedAd({
    //     adID,
    //     attachedFiles: [
    //       {
    //         name: fileName,
    //         type: mediaType,
    //         size: fileSize,
    //       },
    //     ],
    //     adStartDate: new Date(adStartDate),
    //     fields: { campaignName, adName, adLength: `${adLength}`, runFor: `${runFor}` },
    //   }),
    // );
    // }

    // const branches = !adID
    //   ? allBranches
    //   : allBranches?.map(br =>
    //       selectedBranchesID.includes(br.id)
    //         ? { ...br, selected: true }
    //         : { ...br, selected: false },
    //     );

    yield putResolve(reset());
    yield put(setDialogOpen(true));

    const branchesResp = yield call(fetchAdCreationItems);
    yield process.env.NODE_ENV === 'development' && console.log(branchesResp);

    const branches = yield branchesResp.data?.data?.map(item => ({
      ...item,
      screensCollection: item.screensCollection?.map(screen => ({
        ...screen,
        selected: selectedScreens?.includes(screen.id) ?? true,
      })),
    }));

    yield putResolve(
      receivedAdCreationBranches({
        branches,
      }),
    );
    const selectedScrs = yield call(getSelectedScreens, branches);
    const screensCount = yield selectedScreens?.length || selectedScrs?.length;
    const branchesCount = yield call(getSelectedBranchesCount, branches);

    // calculate the reach based on the selected options
    yield put(calcStats({ screensCount, branchesCount }));
  } catch (error) {
    yield console.log(error.toString());
    yield snackbar.error('Something went wrong');
  }
}

function* handleToggleSelect({ payload: { all, branchId, screenId } }) {
  if (all) {
    yield put(selectAll());
  } else {
    yield put(selectItem({ branchId, screenId }));
  }

  const { branches } = yield select(getAdCreation);
  const selectedScreens = yield call(getSelectedScreens, branches);
  const branchesCount = yield call(getSelectedBranchesCount, branches);

  // calculate the reach based on the selected options
  yield put(
    calcStats({
      screensCount: selectedScreens.length,
      branchesCount,
    }),
  );
}

function* handleSubmitAd({ payload: { openSnackbar, closeSnackbar } }) {
  try {
    const { branches, form, fileUpload } = yield select(getAdCreation);
    const file = yield fileUpload.attachedFiles?.[0];
    const mediaType = yield file.type.includes('image')
      ? 'image'
      : file.type.includes('video')
      ? 'video'
      : 'html';

    const selectedScreens = yield call(getSelectedScreens, branches);

    const newAd = yield {
      selectedScreens: JSON.stringify(selectedScreens),
      attachedFiles: file,
      mediaType,
      adStartDate: moment(form.adStartDate).toJSON(),
      ...form.fields,
    };

    const formData = new FormData();
    yield Object.keys(newAd).forEach(key => {
      formData.append(key, newAd[key]);
    });

    yield put(setDialogOpen(false));
    yield put(uploadStart({ openSnackbar }));

    const [uploadPromise, channel] = createUploader(formData);
    yield fork(watchOnProgress, channel);
    const resp = yield call(() => uploadPromise);

    yield process.env.NODE_ENV === 'development' && console.log(resp);
    yield put(uploadFinish({ closeSnackbar }));
    yield snackbar.toast('Ad successfully submitted');
    yield put(loadAds());
  } catch (error) {
    yield fork(closeSnackbar, 'upload-progress');
    if (axios.isCancel(error)) {
      yield snackbar.error('Upload Canceled.');
    } else {
      yield snackbar.error('Something went wrong while submitting your new ad.');
    }
    yield console.log(error.response);
    yield console.log(error.toString());
  }
}

function* handleUploadStart({ payload: { openSnackbar } }) {
  yield fork(openSnackbar);
}
const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* handleUploadFinish({ payload: { closeSnackbar } }) {
  yield call(delay, 7000);
  yield fork(closeSnackbar, 'upload-progress');
}

function* handleCancelUpload() {
  yield source.cancel('Upload canceled by the user.');
}

export default function* watchAdCreation() {
  yield takeLatest(loadAdCreation.toString(), handleLoadAdCreation);
  yield takeLatest(toggleSelect.toString(), handleToggleSelect);
  yield takeLatest(submitAd.toString(), handleSubmitAd);
  yield takeLatest(uploadStart.toString(), handleUploadStart);
  yield takeLatest(uploadFinish.toString(), handleUploadFinish);
  yield takeLatest(cancelUpload.toString(), handleCancelUpload);
  yield takeLatest(duplicateAd.toString(), handleDuplicateAd);
}
