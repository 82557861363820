import React, { memo } from 'react';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import { toggleSelect } from 'redux/features/adCreation.slice';
import { useSelector, useDispatch } from 'react-redux';
import ExpansionPanel from 'components/ExpansionPanel/ExpansionPanel';
import AdCreationForm from 'components/AdCreationForm/AdCreationForm';

function AdCampaign({ steps, activeStep }) {
  const classes = useStyles();
  const transitionDuration = 500;

  const dispatch = useDispatch();
  const { branches } = useSelector(state => state.adCreation);

  return (
    <div className={classes.root}>
      {steps.map((item, i) => {
        const allSelected = branches.every(branch =>
          branch.screensCollection.every(screen => screen.selected),
        );
        return (
          <Collapse
            mountOnEnter
            unmountOnExit
            key={item}
            timeout={transitionDuration}
            in={activeStep === i}
          >
            <Typography className={classes.title} variant="h5">
              {i === 0 ? 'Fill in ad information below' : 'Where do you want to place your ad?'}
            </Typography>
            {i === 0 && <AdCreationForm />}
            {i === 1 && (
              <Button
                onClick={() => dispatch(toggleSelect({ all: true }))}
                color="secondary"
                className={classes.selectAll}
              >
                {allSelected ? 'Unselect All' : 'Select All'}
              </Button>
            )}

            {i === 1 && <ExpansionPanel panelList={branches} />}
          </Collapse>
        );
      })}
    </div>
  );
}

export default memo(AdCampaign);
