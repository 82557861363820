import HNThinWoff from 'assets/fonts/HelveticaNeue-Thin.woff';
import HNLightWoff from 'assets/fonts/HelveticaNeueLight.woff';
import HNBoldWoff from 'assets/fonts/HelveticaNeueBold.woff';

const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream);

const helveticaNeueThin = {
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontWeight: 300,
  fontDisplay: 'swap',
  src: `url(${HNThinWoff}) format('woff')`,
};

const helveticaNeueLight = {
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontWeight: 400,
  fontDisplay: 'swap',
  src: `url(${HNLightWoff}) format('woff')`,
};

const helveticaNeueBold = {
  fontFamily: 'Helvetica Neue',
  fontStyle: 'normal',
  fontWeight: 500,
  fontDisplay: 'swap',
  src: `url(${HNBoldWoff}) format('woff')`,
};

export default {
  '@font-face': [helveticaNeueThin, helveticaNeueLight, helveticaNeueBold],
  html: {
    fontSize: '62%',
  },
  body: {
    minHeight: '100vh',
    paddingRight: '0 !important',
    '& .mui-fixed': {
      paddingRight: '0 !important',
    },
    '@media (min-width: 900px)': {
      overflow: 'overlay',
    },
  },
  b: {
    fontWeight: 500,
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
  svg: {
    fill: 'currentColor',
    overflow: 'hidden',
  },
  input: {
    fontSize: isIOS ? '16px !important' : 'inherit',
  },
  'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
};
