import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, transitions, palette }) => ({
  card: {
    maxWidth: 400,
    width: 'calc(100vw - 32px)',
  },
  typography: {
    fontWeight: 'bold',
    color: '#fff',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    backgroundColor: palette.primary.main,
  },
  icons: {
    marginLeft: 'auto !important',
    '& svg': {
      color: '#fff',
    },
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: transitions.create('transform', {
      duration: transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  checkIcon: {
    color: palette.success.main,
    height: 28,
    width: 28,
  },
  list: {
    backgroundColor: palette.grey.A400,
  },
  listItemText: {
    '& *': {
      color: '#fff',
    },
  },
  avatar: {
    backgroundColor: 'transparent',
  },
  progressContainer: {
    width: '28px !important',
    height: '28px !important',
    position: 'relative',
  },
  progressBG: {
    color: 'rgba(102, 51, 153, 0.4) !important',
  },
  progress: {
    color: palette.primary.light,
    position: 'absolute',
    width: '28px !important',
    height: '28px !important',
    '& svg': {
      overflow: 'visible',
    },
    '& circle': {
      strokeWidth: 7,
    },
  },
}));
