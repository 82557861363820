import {
  loginUser,
  logoutUser,
  reAuthUser,
  registerUser,
  loginUserSuccess,
  setErrors,
} from 'redux/features/auth.slice';
import { put, call, takeLatest } from 'redux-saga/effects';
import API from 'utils/api';
import snackbar from 'utils/snackbar';
import jwtDecode from 'jwt-decode';
import loopObject from 'utils/helpers/loopObject';

const login = fields => API.post('/login', fields);
// const register = fields => API.post('/signup', fields);
const saveUserToLocalStorage = user => localStorage.setItem('user', JSON.stringify(user));
const getUserFromLocalStorage = () => localStorage.getItem('user');

function* handleLoginUser(action) {
  try {
    const { fields } = yield action.payload;
    yield put(setErrors({}));
    const resp = yield call(login, fields);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    const user = yield resp.data;
    yield call(saveUserToLocalStorage, user);
    yield put(loginUserSuccess(user));
  } catch (error) {
    const errors = yield call(loopObject, error.response.data, ([key, value]) => [key, value[0]]);
    yield put(setErrors(errors));
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('Unable to login.');
  }
}

function* handleRegisterUser(action) {
  try {
    // const { fields } = yield action.payload;
    // yield put(setErrors({}));
    // const resp = yield call(register, fields);
    // yield process.env.NODE_ENV === 'development' && console.log(resp);
    // const user = yield resp.data;
    // yield call(saveUserToLocalStorage, user);
    // yield put(loginUserSuccess(user));
  } catch (error) {
    const errors = yield call(loopObject, error.response.data, ([key, value]) => [key, value[0]]);
    yield put(setErrors(errors));
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('Unable to signup.');
  }
}

function* handleLogoutUser() {
  yield localStorage.removeItem('user');
}

function* handleReAuthUser() {
  const userString = yield call(getUserFromLocalStorage);
  const user = yield JSON.parse(userString);
  if (userString) {
    // logout user when token expires
    const decodedToken = yield jwtDecode(user.token);
    if (decodedToken.exp * 1000 < Date.now()) {
      yield localStorage.removeItem('user');
      yield put(logoutUser());
    }
  }
}

export default function* watchAuth() {
  yield takeLatest(loginUser.toString(), handleLoginUser);
  yield takeLatest(logoutUser.toString(), handleLogoutUser);
  yield takeLatest(reAuthUser.toString(), handleReAuthUser);
  yield takeLatest(registerUser.toString(), handleRegisterUser);
}
