import React, { useState, Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import MuiExpansionPanel from '@material-ui/core/Accordion';
import MuiExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import MuiExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import List from 'components/List/List';
import MuiList from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';

function ExpansionPanel({ panelList }) {
  const [expanded, setExpanded] = useState('panel1');
  const classes = useStyles();

  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {panelList.map((panelItem, i, arr) => {
        return (
          <Fragment key={i}>
            <MuiExpansionPanel
              TransitionProps={{
                mountOnEnter: true,
                unmountOnExit: true,
              }}
              className={classes.expPanel}
              expanded={expanded === `panel${i + 1}`}
              onChange={handleChange(`panel${i + 1}`)}
            >
              <MuiExpansionPanelSummary
                className={classes.expPanelSummary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <ListItemIcon className={classes.icon}>
                  <Icon>location_on</Icon>
                </ListItemIcon>
                <Typography>{panelItem.branch}</Typography>
              </MuiExpansionPanelSummary>
              <MuiExpansionPanelDetails className={classes.expPanelDetails}>
                <MuiList dense className={classes.list}>
                  {panelItem.screensCollection && (
                    <List branchId={panelItem.id} list={panelItem.screensCollection} />
                  )}
                </MuiList>
              </MuiExpansionPanelDetails>
            </MuiExpansionPanel>
            {i < arr.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </div>
  );
}

ExpansionPanel.propTypes = {
  panelList: PropTypes.array.isRequired,
};

export default memo(ExpansionPanel);
