import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, breakpoints, shape, transitions }) => ({
  root: {
    display: 'flex',
  },
  expPanelSummary: {
    '&&&&': {
      cursor: 'default',
    },
    '& svg': {
      fontSize: '2.6rem',
    },
    '&& > div': {
      margin: 0,
      alignItems: 'center',
    },
  },
  expPanelDetails: {
    '&&': {
      padding: spacing(0, 2, 1.5),
      flexDirection: 'column',
    },
  },
  expandIcon: {
    transform: props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'),
    transition: transitions.create('transform', {
      easing: transitions.easing.ease,
      duration: transitions.duration.short,
    }),
  },
  cardHeader: {
    padding: spacing(1.5, 1),
    cursor: 'pointer',
    marginRight: 'auto',
  },
  cardContent: {
    padding: spacing(1, 2),
    flex: 1,
  },
  cardActions: {
    '&&': {
      justifyContent: 'center',
      paddingBottom: 0,
    },
  },
}));
