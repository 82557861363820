/**
 * Default theme mixins provided by MUI
 * https://material-ui.com/customization/default-theme/?expand-path=$.mixins
 */

import palette from './palette.theme';

export default {
  boxShadows: {
    primary: {
      light: '0px 1px 4px 0px rgba(150, 150, 150, 0.30)',
      dark: '0px 1px 4px 0px rgba(0, 0, 0, 0.10)',
    },
  },
  scrollbar: {
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      borderRadius: 8,
    },
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette.secondary.main,
      borderRadius: 8,
    },
  },
  keyframes: {
    spin: {
      '@keyframes spin': {
        '0%': {
          transform: 'rotate(0deg)',
        },
        '100%': {
          transform: 'rotate(360deg)',
        },
      },
    },
    pulse: {
      '@keyframes pulse': {
        '0%': {
          opacity: 1,
        },
        '50%': {
          opacity: 0.4,
        },
        '100%': {
          opacity: 1,
        },
      },
    },
  },
};
