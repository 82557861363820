import * as Sentry from '@sentry/react';
import store from 'redux/store';

if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
  });

  Sentry.configureScope(scope => {
    const currentUser = store?.getState()?.auth?.currentUser;
    if (currentUser) {
      const {
        email,
        partner: { id },
      } = currentUser;
      scope.setUser({ email, id });
    }
    scope.setTag('route_path', window.location.pathname);
    scope.setTag('environment', process.env.NODE_ENV);
  });
}
