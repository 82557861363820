import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TvIcon from '@material-ui/icons/Tv';
import StayPrimaryPortraitIcon from '@material-ui/icons/StayPrimaryPortrait';
import CheckButton from 'components/CheckButton/CheckButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import { toggleSelect } from 'redux/features/adCreation.slice';

function List({ list, branchId }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return list.map((item, i) => (
    <ListItem
      onClick={() => dispatch(toggleSelect({ branchId, screenId: item.id }))}
      button
      key={i}
    >
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          {item.orientation === 'portrait' ? <StayPrimaryPortraitIcon /> : <TvIcon />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={classes.listItemText}
        primary={item.title}
        secondary={item.orientation}
      />
      <div className={classes.selectButton}>
        <CheckButton selected={item.selected} />
      </div>
    </ListItem>
  ));
}

List.propTypes = {
  list: PropTypes.array.isRequired,
};

export default memo(List);
