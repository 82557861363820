import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    '&&': {
      backgroundColor: 'transparent',
      padding: spacing(2, 0, 2.5),
      [breakpoints.up('sm')]: {
        padding: spacing(1, 0, 3),
      },
    },
  },
}));
