import React, { memo } from 'react';
import useStyles from './styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CountUp from 'react-countup';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function AdCreationStat({ title, icon, count }) {
  const classes = useStyles();
  const up600 = useMediaQuery('(min-width:600px)');

  const renderCount = ({ start, end }) => <CountUp start={start} end={end} separator="," />;

  return (
    <Card className={classes.root} variant="outlined">
      {up600 && <Typography variant="h6">{renderCount(count)}</Typography>}
      <div className={classes.textContainer}>
        {icon}
        {!up600 && <Typography variant="h6">{renderCount(count)}</Typography>}
        <Typography>{title}</Typography>
      </div>
    </Card>
  );
}

export default memo(AdCreationStat);
