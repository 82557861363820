import React, { memo, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import TvIcon from '@material-ui/icons/Tv';
import { useHistory } from 'react-router-dom';
import StatCard from 'components/StatCard/StatCard';
import useStyles from './styles';

function BranchCard(props) {
  const { id, branch, brand, province, area, city, screens, adsCount } = props;
  const [expanded, setExpanded] = useState(true);
  const classes = useStyles({ expanded });
  const history = useHistory();

  return (
    <Card>
      <Accordion expanded={expanded}>
        <AccordionSummary className={classes.expPanelSummary}>
          <CardHeader
            onClick={() => setExpanded(st => !st)}
            className={classes.cardHeader}
            title={`${brand} - ${branch}`}
            subheader={`${area ? area : province}, ${city}`}
          />
          <Tooltip title="Expand branch" placement="top" arrow>
            <IconButton aria-label="Expand" onClick={() => setExpanded(st => !st)}>
              <ExpandMoreIcon className={classes.expandIcon} />
            </IconButton>
          </Tooltip>
        </AccordionSummary>
        <AccordionDetails className={classes.expPanelDetails}>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <StatCard
                  variant="horizontal"
                  title="screens in branch"
                  icon={<TvIcon />}
                  onClick={() => history.push(`/branch/${id}/screens`)}
                  stat={screens}
                  count={{ start: 0, end: parseInt(screens, 10) }}
                  duration={2}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StatCard
                  variant="horizontal"
                  title="ads on screens"
                  onClick={() => history.push(`/branch/${id}/ads`)}
                  icon={<FeaturedVideoIcon />}
                  stat={adsCount}
                  count={{ start: 0, end: parseInt(adsCount, 10) }}
                  duration={2}
                />
              </Grid>
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

export default memo(BranchCard);
