import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, shadows, shape, breakpoints }) => ({
  root: {
    background: `linear-gradient(to left, ${palette.secondary.dark}, ${palette.primary.main})`,
    padding: 0,
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: props => (props.scrolled ? shadows[2] : shadows[0]),
  },
  toolbar: {
    flex: 1,
    marginLeft: 0,
    maxWidth: 1050,
    padding: spacing(0, 1.5, 0, 1),
    [breakpoints.up('sm')]: {
      padding: spacing(0, 2),
      margin: '0 auto',
    },
    [breakpoints.up('md')]: {
      padding: spacing(0, 6),
    },
  },
  user: {
    boxShadow: shadows[1],
    marginLeft: 'auto',
    '& .MuiChip-label': {
      paddingRight: 8,
      [breakpoints.up('sm')]: {
        paddingRight: 12,
      },
    },
  },
  logoButton: {
    '&&': {
      marginLeft: spacing(1),
      padding: spacing(1, 0, 1, 1),
      [breakpoints.up('sm')]: {
        padding: spacing(1.25),
      },
      [breakpoints.up('sm')]: {
        borderRadius: shape.borderRadius,
      },
    },
    '& svg': {
      color: '#fff',
    },
  },
  logo: {
    width: '4em',
  },
  symbol: {
    width: '1.2em',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flex: {
    display: 'flex',
  },
}));
