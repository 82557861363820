import React, { memo } from 'react';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Zoom from '@material-ui/core/Zoom';

function CheckButton({ selected, radio }) {
	return selected ? (
		<Zoom in={selected}>
			{radio ? (
				<RadioButtonCheckedIcon color="secondary" />
			) : (
				<CheckCircleIcon color="secondary" />
			)}
		</Zoom>
	) : (
		<RadioButtonUncheckedIcon color="secondary" />
	);
}

CheckButton.propTypes = {
	selected: PropTypes.bool.isRequired,
	radio: PropTypes.bool
};

export default memo(CheckButton);
