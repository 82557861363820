import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    padding: spacing(2, 1),
    [breakpoints.up('sm')]: {
      padding: spacing(2, 3),
    },
  },
  title: {
    marginBottom: spacing(2),
  },
  selectAll: {
    display: 'block',
    marginLeft: 'auto',
  },
}));
