import React, { useState } from 'react';
import Navbar from 'components/Navbar/Navbar';
import useStyles from './styles';
import shape4w from 'assets/images/shape-4-w.png';
import shape2w from 'assets/images/shape-2-w.png';
import shape3w from 'assets/images/shape-3-w.png';
import shapehappymanw from 'assets/images/shape-happyman-w.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ErrorBoundary from 'utils/ErrorBoundary';
import CreateAdDialog from 'components/CreateAdDialog/CreateAdDialog';

function Layout({ children }) {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const up600 = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Navbar isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      <div className={classes.flexContainer}>
        <div className={classes.gradientBG}>
          {up600 && (
            <>
              <img src={shape4w} alt="" />
              <img src={shape4w} alt="" />
              <img src={shape3w} alt="" />
              <img src={shapehappymanw} alt="" />
              <img src={shape2w} alt="" />
            </>
          )}
        </div>

        <div className={classes.contentContainer}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </div>
      <CreateAdDialog />
    </>
  );
}

export default Layout;
