import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Layout from 'components/Layout/Layout';
import { reAuthUser } from 'redux/features/auth.slice';

const PrivateRoute = ({ children, ...rest }) => {
  const { currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reAuthUser());
  }, [dispatch]);

  return !!currentUser ? (
    <Route {...rest}>
      <Layout>{children}</Layout>
    </Route>
  ) : (
    <Redirect to={'/login'} />
  );
};

export default PrivateRoute;
