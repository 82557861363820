import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';
import FileChip from 'components/FileChip/FileChip';
import Collapse from '@material-ui/core/Collapse';

function FileChips({ attachedFiles, setAttachedFiles }) {
  const classes = useStyles();

  return (
    <Collapse in={!!attachedFiles.length}>
      <div className={classes.chipsContainer}>
        {attachedFiles.map((file, index, arr) => (
          <FileChip
            key={index}
            handleDelete={() => {
              setAttachedFiles(arr.filter((f, i) => i !== index));
            }}
            file={file}
          />
        ))}
      </div>
    </Collapse>
  );
}

FileChips.propTypes = {
  attachedFiles: PropTypes.array.isRequired,
  setAttachedFiles: PropTypes.func,
};

export default memo(FileChips);
