import React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './styles';

function EmptyBox({ label, boxProps, show }) {
  const classes = useStyles();
  return (
    <Collapse in={show} mountOnEnter unmountOnExit timeout={400}>
      <Box {...boxProps} clone>
        <Paper className={classes.root}>
          <div>
            <Typography color="textSecondary" variant="h6">
              {label}
            </Typography>
          </div>
        </Paper>
      </Box>
    </Collapse>
  );
}

EmptyBox.defaultProps = {
  show: true,
};

export default EmptyBox;
