import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, breakpoints, shape, transitions }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacing(1, 2),
    margin: spacing(0, 0.5),
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '& svg': {
      marginRight: spacing(1),
    },
  },
}));
