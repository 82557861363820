import axios from 'axios';
import store from 'redux/store';

const baseURL = process.env.REACT_APP_API_BASEURL;

const api = axios.create({
  baseURL,
});

api.interceptors.request.use(function (config) {
  const token = store.getState()?.auth?.currentUser?.token;
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export default api;
