import React from 'react';
import useStyles from './styles';
import { useSpring, animated } from 'react-spring';

function MouseParallax() {
  const classes = useStyles();

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

  const manTrans = (x, y) => `translate3d(${x / 3.6 + 50}px,${y / 3 + 30}px,0)`;
  const screenTrans = (x, y) => `translate3d(${x / 4 + 50}px,${y / 4 + 30}px,0)`;
  const lightStroke = (x, y) => `translate3d(${x / 9 + 330}px,${y / 7 + 120}px,0) rotate(220deg)`;
  const stroke = (x, y) => `translate3d(${x / 6.6 + 50}px,${y / 5 - 60}px,0)`;
  const strokeSmall = (x, y) => `translate3d(${x / 10 + 70}px,${y / 9 - 240}px,0) rotate(50deg)`;
  const circle = (x, y) => `translate3d(${x / 7.3 + 320}px,${y / 6 - 10}px,0)`;
  const happyman = (x, y) => `translate3d(${x / 7.7 + 410}px,${y / 7 - 200}px,0)`;

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 140, tension: 350, friction: 240 },
    // config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <div
      className={classes.root}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <animated.div
        className={classes.happyman}
        style={{ transform: props.xy.interpolate(happyman) }}
      />
      <animated.div
        className={classes.circle}
        style={{ transform: props.xy.interpolate(circle) }}
      />
      <animated.div
        className={classes.stroke}
        style={{ transform: props.xy.interpolate(stroke) }}
      />
      <animated.div
        className={classes.strokeSmall}
        style={{ transform: props.xy.interpolate(strokeSmall) }}
      />
      <animated.div
        className={classes.lightStroke}
        style={{ transform: props.xy.interpolate(lightStroke) }}
      />
      <animated.div
        className={classes.screen}
        style={{ transform: props.xy.interpolate(screenTrans) }}
      />
      <animated.div className={classes.man} style={{ transform: props.xy.interpolate(manTrans) }} />
      <animated.div
        className={`${classes.screen} ${classes.screenLeft}`}
        style={{ transform: props.xy.interpolate(screenTrans) }}
      />
      <animated.div
        className={`${classes.man} ${classes.manRight}`}
        style={{ transform: props.xy.interpolate(manTrans) }}
      />
    </div>
  );
}

export default MouseParallax;
