import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  root: {
    padding: spacing(3, 2),
    '& button': {
      width: '100%',
    },
  },
  dashedBtn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.grey[100],
    padding: spacing(3, 2, 3.5),
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    borderColor: palette.divider,
    color: palette.text.secondary,
    '& .MuiTypography-root': {
      color: 'inherit',
      marginTop: spacing(1),
    },
  },
}));
