import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  contentContainer: {
    position: 'relative',
    width: '100%',
    '& > div': {
      maxWidth: 1060,
      margin: '0 auto',
      padding: spacing(1, 1.5, 0),
      '@media (min-width: 400px)': {
        padding: spacing(1, 2, 0),
      },
      [breakpoints.up('md')]: {
        padding: spacing(3, 7),
      },
    },
  },
  gradientBG: {
    height: 220,
    backgroundImage: `linear-gradient(to left, ${palette.secondary.dark}, ${palette.primary.main})`,
    position: 'absolute',
    zIndex: '-1',
    width: '100%',
    top: 0,
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      height: 'auto',
    },
    '& img:nth-child(1)': {
      top: '40%',
      left: '-2%',
      width: 200,
      opacity: 0.6,
    },
    '& img:nth-child(2)': {
      opacity: 0.3,
      width: 300,
      top: '2%',
      right: '-10%',
    },
    '& img:nth-child(3)': {
      opacity: 0.1,
      width: 100,
      bottom: '-20%',
      left: '40%',
    },
    '& img:nth-child(4)': {
      opacity: 0.11,
      width: 50,
      top: '5%',
      left: '30%',
    },
    '& img:nth-child(5)': {
      opacity: 0.1,
      width: 110,
      top: '8%',
      left: '60%',
    },
  },
}));
