import React, { memo } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import useStyles from './styles';

function DashedButton({ label, icon, onClick }) {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <ButtonBase onClick={onClick}>
        <div className={classes.dashedBtn}>
          {icon}
          <Typography color="textSecondary" variant="h6">
            {label}
          </Typography>
        </div>
      </ButtonBase>
    </Paper>
  );
}

export default memo(DashedButton);
