import React, { memo, useState } from 'react';
import useStyles from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { formatFileName, renderIcon } from 'utils/helpers/formatFile';
import CircularProgress from '@material-ui/core/CircularProgress';
import { cancelUpload } from 'redux/features/adCreation.slice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WarningDialog from 'components/WarningDialog/WarningDialog';

const UploadSnackbar = React.forwardRef(({ id, attachedFilesArr }, ref) => {
  const [expanded, setExpanded] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const adCreation = useSelector(state => state.adCreation);
  const { progress, uploading } = adCreation.fileUpload;

  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });

  const handleDismiss = () => {
    uploading ? setDialogOpen(true) : closeSnackbar(id);
  };

  return (
    <>
      <WarningDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onProceed={() => {
          dispatch(cancelUpload());
          closeSnackbar(id);
        }}
        title="Cancel Upload?"
        description="Your upload is not complete. Would you like to cancel the upload?"
        closeLabel="Continue Upload"
        proceedLabel="Cancel Upload"
        containedBtn="close"
      />
      <Card className={classes.card} ref={ref}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <Typography className={classes.typography}>
            Uploading {attachedFilesArr.length} {attachedFilesArr.length > 1 ? 'files' : 'file'}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List className={classes.list}>
            {attachedFilesArr.map((f, i, arr) => {
              return (
                <ListItem divider={i < arr.length - 1} key={i}>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <Icon>{renderIcon(f.type)}</Icon>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={formatFileName(f.name, up600 ? 28 : 22)}
                    // secondary={formatFileSize(f.size)}
                    className={classes.listItemText}
                  />
                  <div className={classes.progressContainer}>
                    {progress < 100 ? (
                      <>
                        <CircularProgress
                          className={classes.progress}
                          variant="static"
                          value={progress}
                        />
                        <CircularProgress
                          className={clsx(classes.progressBG, classes.progress)}
                          variant="static"
                          value={100}
                        />
                      </>
                    ) : (
                      <CheckCircleIcon className={classes.checkIcon} />
                    )}
                  </div>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </Card>
    </>
  );
});

UploadSnackbar.defaultProps = {
  attachedFilesArr: [],
  id: '',
};

export default memo(UploadSnackbar);
