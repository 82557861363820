import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadBranchAds = createAction('branchAdsList/loadBranchAds');
export const loadScreens = createAction('branchAdsList/loadScreens');
export const receivedAds = createAction('branchAdsList/receivedAds');
export const receivedScreens = createAction('branchAdsList/receivedScreens');
export const deleteBranchAdSuccess = createAction('branchAdsList/deleteBranchAdSuccess');
export const clearBranch = createAction('branchAdsList/clearBranch');

const initialState = {
  loading: true,
};

export default createReducer(initialState, {
  [receivedAds]: (state, action) => {
    const { branchInfo, adsList, myAdsList } = action.payload;
    return {
      ...state,
      loading: false,
      ...branchInfo,
      adsList,
      myAdsList,
    };
  },
  [receivedScreens]: (state, action) => {
    const { branchInfo, screensCollection } = action.payload;
    return {
      ...state,
      loading: false,
      ...branchInfo,
      screensCollection,
    };
  },
  [deleteBranchAdSuccess]: (state, action) => {
    const id = action.payload;
    return {
      ...state,
      myAdsList: state.myAdsList.filter(ad => ad.id !== id),
    };
  },
  [clearBranch]: () => {
    return {
      loading: true,
    };
  },
});
