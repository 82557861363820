import React, { memo } from 'react';
import useStyles from './styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

function ProfilePopover({ anchorEl, setAnchorEl, username, email, onSignOut }) {
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const classes = useStyles();
  const up600 = useMediaQuery('(min-width:600px)');

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.popover}>
        <div className={classes.popoverContent}>
          {up600 ? (
            <Typography variant="body2" className={classes.name} gutterBottom>
              Signed in as <span className={classes.bolder}>{username}</span>
            </Typography>
          ) : (
            <>
              <Typography variant="body2" className={classes.name} gutterBottom>
                Signed in as
              </Typography>
              <Typography variant="body2" className={classes.name} gutterBottom>
                <span className={classes.bolder}>{username}</span>
              </Typography>
            </>
          )}
          <Typography variant="caption" className={classes.email}>
            {email}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.signOutButton}
            onClick={onSignOut}
            endIcon={<ExitToAppIcon />}
          >
            Sign Out
          </Button>
        </div>
      </div>
    </Popover>
  );
}

export default memo(ProfilePopover);
