import { createAction, createReducer } from '@reduxjs/toolkit';
import { adsPlayedPerDay, visitorsPerDay } from 'config/constants';

export const loadAdCreation = createAction('adCreation/loadAdCreation');
export const receivedAdCreationBranches = createAction('adCreation/receivedAdCreationBranches');
export const selectItem = createAction('adCreation/selectItem');
export const selectAll = createAction('adCreation/selectAll');
export const toggleSelect = createAction('adCreation/toggleSelect');
export const setWizardItems = createAction('adCreation/setWizardItems');
export const calcStats = createAction('adCreation/calcStats');
export const submitAd = createAction('adCreation/submitAd');
export const setDialogOpen = createAction('adCreation/setDialogOpen');
export const setForm = createAction('adCreation/setForm');
export const setFiles = createAction('adCreation/setFiles');
export const uploadStart = createAction('adCreation/uploadStart');
export const uploadFinish = createAction('adCreation/uploadFinish');
export const setProgress = createAction('adCreation/setProgress');
export const cancelUpload = createAction('adCreation/cancelUpload');
export const reset = createAction('adCreation/reset');
export const receivedAd = createAction('adCreation/receivedAd');
export const duplicateAd = createAction('adCreation/duplicateAd');

export const formFields = [
  { item: 'campaignName', label: 'Campaign Name', type: 'autoComplete' },
  { item: 'adName', label: 'Ad Name', type: 'text' },
  { item: 'runFor', label: 'Run Ad for (weeks)', type: 'number' },
  {
    item: 'adLength',
    label: 'Ad Length (seconds)',
    type: 'select',
    selectItems: [
      { value: '15', label: '15 seconds' },
      { value: '30', label: '30 seconds' },
      { value: '45', label: '45 seconds' },
      { value: '60', label: '60 seconds' },
    ],
  },
];

const initFields = formFields.reduce((ac, f) => ({ ...ac, [f.item]: '' }), {});

const initialState = {
  loading: true,
  adID: '',
  branches: [],
  stats: {
    screens: { start: 0, end: 0 },
    visitors: { start: 0, end: 0 },
    adLoops: { start: 0, end: 0 },
  },
  form: {
    fields: initFields,
    validation: initFields,
    adStartDate: new Date(),
  },
  fileUpload: {
    attachedFiles: [],
    uploading: false,
    progress: 0,
  },
  dialogOpen: false,
};

export default createReducer(initialState, {
  [receivedAdCreationBranches]: (state, action) => {
    const { branches } = action.payload;
    return {
      ...state,
      loading: false,
      branches,
    };
  },
  [receivedAd]: (state, action) => {
    const { adID = '', attachedFiles = [], fields, adStartDate } = action.payload;

    return {
      ...state,
      loading: false,
      adID,
      fileUpload: {
        ...state.fileUpload,
        attachedFiles,
      },
      form: {
        ...state.form,
        fields,
        adStartDate,
      },
    };
  },
  [selectItem]: (state, action) => {
    const { branchId, screenId } = action.payload;

    return {
      ...state,
      branches: state.branches.map(branch =>
        branch.id === branchId
          ? {
              ...branch,
              screensCollection: branch.screensCollection?.map(card =>
                card.id === screenId ? { ...card, selected: !card.selected } : card,
              ),
            }
          : branch,
      ),
    };
  },
  [selectAll]: (state, action) => {
    const allSelected = state.branches.every(branch =>
      branch.screensCollection.every(screen => screen.selected),
    );
    return {
      ...state,
      branches: state.branches.map(item => ({
        ...item,
        screensCollection: item.screensCollection.map(screen => ({
          ...screen,
          selected: !allSelected,
        })),
      })),
    };
  },
  [calcStats]: (state, action) => {
    const { screensCount, branchesCount } = action?.payload || {};
    const visitors = branchesCount * 30 * visitorsPerDay;
    const loops = screensCount * 30 * adsPlayedPerDay;

    return {
      ...state,
      stats: {
        ...state.stats,
        screens: { start: state.stats.screens.end, end: screensCount },
        visitors: { start: state.stats.visitors.end, end: visitors },
        adLoops: { start: state.stats.adLoops.end, end: loops },
      },
    };
  },
  [setDialogOpen]: (state, action) => {
    return {
      ...state,
      dialogOpen: action.payload,
    };
  },
  [setForm]: (state, action) => {
    const { fields, validation, adStartDate } = action.payload;
    return {
      ...state,
      form: {
        ...state.form,
        fields,
        validation,
        adStartDate,
      },
    };
  },
  [setFiles]: (state, action) => {
    return {
      ...state,
      fileUpload: {
        ...state.fileUpload,
        attachedFiles: action.payload,
      },
    };
  },
  [setProgress]: (state, action) => {
    const progress = action.payload;
    return {
      ...state,
      fileUpload: {
        ...state.fileUpload,
        progress,
      },
    };
  },
  [uploadStart]: (state, action) => {
    return {
      ...state,
      fileUpload: {
        ...state.fileUpload,
        uploading: true,
        progress: 0,
      },
    };
  },
  [uploadFinish]: (state, action) => {
    return {
      ...state,
      fileUpload: {
        ...state.fileUpload,
        uploading: false,
      },
    };
  },
  [reset]: (state, action) => {
    return {
      ...state,
      form: {
        ...state.form,
        fields: initFields,
        validation: initFields,
        adStartDate: new Date(),
      },
      fileUpload: {
        ...state.fileUpload,
        attachedFiles: [],
      },
    };
  },
});
