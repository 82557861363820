import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import clsx from 'clsx';
import useStyles from './styles';

function FilterBy({
  options,
  selection,
  buttonLabel,
  onSelection,
  elementId,
  renderOptionLabel,
  className,
  disabled,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isArrayOfObjects = typeof options[0] === 'object';

  return (
    <div className={clsx(classes.root, className)}>
      <Button
        disabled={disabled}
        onClick={evt => setAnchorEl(evt.currentTarget)}
        aria-controls={elementId}
        endIcon={<ArrowDropDownCircleIcon />}
      >
        <Typography noWrap component="span" className={classes.buttonLabel}>
          {buttonLabel}
        </Typography>
      </Button>
      <Menu
        id={elementId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map(option =>
          isArrayOfObjects ? (
            <MenuItem
              key={option.value}
              selected={option.value === selection.value}
              onClick={() => {
                handleClose();
                onSelection(option);
              }}
            >
              {renderOptionLabel ? renderOptionLabel(option.label) : option.label}
            </MenuItem>
          ) : (
            <MenuItem
              key={option}
              selected={option === selection}
              onClick={() => {
                handleClose();
                onSelection(option);
              }}
            >
              {renderOptionLabel ? renderOptionLabel(option) : option}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
}

export default FilterBy;

FilterBy.defaultProps = {
  elementId: 'filter-by',
};
