import { all } from 'redux-saga/effects';
import authSaga from './auth.saga';
import branchesSaga from './branches.saga';
import branchAdsListSaga from './branchAdsList.saga';
import adCreationSaga from './adCreation.saga';
import adsSaga from './ads.saga';

export default function* rootSaga() {
  yield all([authSaga(), branchesSaga(), branchAdsListSaga(), adCreationSaga(), adsSaga()]);
}
