import React, { memo, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import numeral from 'numeral';
import StatCard from 'components/StatCard/StatCard';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import AllInclusiveOutlinedIcon from '@material-ui/icons/AllInclusiveOutlined';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import TvIcon from '@material-ui/icons/Tv';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import { useDispatch } from 'react-redux';
import { deleteAd } from 'redux/features/ads.slice';
// import { loadAdCreation } from 'redux/features/adCreation.slice';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MediaCard from 'components/MediaCard/MediaCard';
import useStyles from './styles';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import Transition from 'components/Transition/Transition';
import { duplicateAd } from 'redux/features/adCreation.slice';
// import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { useLocation } from 'react-router-dom';

function MyAdsCard(props) {
  const {
    adTitle,
    campaign,
    startDate,
    endDate,
    loopsPlayed,
    loopsTotal,
    numOfscreens,
    numOfbranches,
    mediaUrl,
    mediaType,
    duration,
    branchId,
    id,
    closeButton,
    onDeleteAd,
    // personal,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const stats = [
    {
      title: 'Start date',
      icon: <TodayOutlinedIcon />,
      stat: moment(startDate).format('D/M/YYYY'),
    },
    {
      title: 'End date',
      icon: <EventOutlinedIcon />,
      stat: moment(endDate).format('D/M/YYYY'),
    },
    {
      title: 'Duration',
      icon: <HourglassEmptyRoundedIcon />,
      stat: `${duration} seconds`,
    },
    {
      title: 'Estimated ad loops',
      icon: <AllInclusiveOutlinedIcon />,
      stat: (
        <Box>
          {numeral(loopsPlayed).format('0,0')}
          <Box component="span" fontWeight={400} fontSize="1.5rem">
            {' '}
            /{' '}
          </Box>
          {numeral(loopsTotal).format('0,0')}
        </Box>
      ),
    },
    {
      title: 'Screens',
      icon: <TvIcon />,
      stat: numeral(numOfscreens).format('0,0'),
    },
    {
      title: 'Branches',
      icon: <StorefrontRoundedIcon />,
      stat: numeral(numOfbranches).format('0,0'),
    },
  ];

  const cardActions = [
    ...(pathname === '/ads'
      ? [
          {
            title: 'Duplicate Ad',
            icon: <FileCopyOutlinedIcon />,
            onClick: () => {
              dispatch(duplicateAd(props));
            },
          },

          {
            title: 'Delete Ad',
            icon: <DeleteOutlinedIcon />,
            onClick: () => {
              setWarningDialogOpen(true);
            },
          },
        ]
      : []),
    //   {
    //     title: 'Edit Ad',
    //     icon: <EditOutlinedIcon />,
    //     onClick: () => {},
    //   },
    ...(closeButton ? [closeButton] : []),
  ];

  const handleClose = () => setWarningDialogOpen(false);
  const handleSubmit = () => {
    dispatch(deleteAd({ id, branchId }));
    setWarningDialogOpen(false);
    onDeleteAd && onDeleteAd(id);
  };

  return (
    <>
      <WarningDialog
        open={warningDialogOpen}
        onClose={handleClose}
        onProceed={handleSubmit}
        title="Delete Ad?"
        description="You are about to delete an ad, this action is irreversible."
        closeLabel="Cancel"
        proceedLabel="Yes, Delete!"
      />
      <Transition variant="grow" in={!!id} timeout={!!closeButton ? 0 : 800}>
        <CardWrapper
          title={adTitle}
          subheader={campaign}
          // chips={[{ label: `Personal Ad`, icon: <AccountCircleRoundedIcon /> }]}
          defaultExpanded
          cardActions={cardActions}
          disabled={!!closeButton}
        >
          <CardContent className={classes.cardContent}>
            <Grid container spacing={1}>
              {stats.map(({ title, icon, stat }) => (
                <Grid item xs={6} sm={4} md={4} key={title}>
                  <StatCard title={title} icon={icon} stat={stat} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
          <MediaCard
            url={mediaUrl}
            className={classes.cardMedia}
            title={adTitle}
            mediaType={mediaType}
            onClick={() => window.open(mediaUrl, '_blank')}
          />
        </CardWrapper>
      </Transition>
    </>
  );
}

export default memo(MyAdsCard);
