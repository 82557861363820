import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing }) => ({
  pageTitle: {
    color: '#fff',
    margin: 0,
  },
  flex: {
    margin: spacing(3, 0, 0),
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    margin: spacing(1, 0),
  },
  listItem: {
    margin: spacing(0, 0, 4),
  },
}));
