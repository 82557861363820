import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ shape, breakpoints, spacing }) => ({
  root: {
    borderRadius: shape.borderRadius,
    position: 'relative',
    overflow: 'hidden',
  },
  media: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  typography: {
    padding: spacing(0.5, 3, 0),
    margin: 'auto',
    fontWeight: 500,
    textShadow: '0px 2px 5px rgba(0,0,0,0.2)',
    [breakpoints.up('md')]: {
      fontSize: '1.3rem',
    },
  },
  overlay: {
    cursor: 'pointer',
    zIndex: 2,
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: 'all .3s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,0.4)',
    '& > div': {
      minHeight: ({ showTitle }) => (showTitle ? '82px' : 'unset'),
      color: '#fff',
      transition: 'all .3s',
      transform: 'scale(1.2)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& svg': {
        fontSize: '4.3rem',
        filter: 'drop-shadow( 0px 2px 5px rgba(0, 0, 0, 0.2))',
      },
    },
  },
  hover: {
    '&:hover > div': {
      transform: 'scale(1)',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 0,
    },
  },
}));
