import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, shape, mixins, breakpoints }) => ({
  skeleton: {
    minWidth: 160,
    margin: spacing(1),
    borderRadius: shape.borderRadius,
  },
  mediaCard: {
    margin: spacing(1),
    height: 'auto',
    minHeight: 170,
    minWidth: 130,
    [breakpoints.up('sm')]: {
      minWidth: 160,
    },
  },
  ads: {
    overflowY: 'auto',
    maxHeight: 280,
    ...mixins.scrollbar,
  },
  dialog: {
    '& .MuiDialogContent-root': {
      padding: spacing(0, 0, 2),
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
  },
}));
