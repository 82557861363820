import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadBranches = createAction('branches/loadBranches');
export const receivedBranches = createAction('branches/receivedBranches');

const initialState = {
  loading: true,
  list: [],
  count: 0,
  page: 1,
};

export default createReducer(initialState, {
  [receivedBranches]: (state, action) => {
    const { list, count } = action.payload;
    return {
      ...state,
      loading: false,
      list,
      count,
    };
  },
});
