const formatFileName = (name, maxChars) => {
  return name.length > maxChars
    ? `${name.slice(0, 12)}....${name.slice(name.length - 7, name.length)}`
    : name;
};

const roundNumber = num => Math.round(num * 10) / 10;
const formatFileSize = size => {
  if (size >= 1000000000) {
    return `${roundNumber(size / 1000000000)} GB`;
  } else if (size >= 1000000) {
    return `${roundNumber(size / 1000000)} MB`;
  } else if (size >= 1000) {
    return `${Math.round(size / 1000)} KB`;
  } else {
    return `${Math.round(size)} B`;
  }
};

const renderIcon = fileType => {
  if (fileType.includes('image')) {
    return 'image';
  } else if (fileType.includes('video')) {
    return 'play_circle_outline';
  } else if (fileType.includes('html')) {
    return 'code';
  } else {
    return 'attachment';
  }
};

export { formatFileName, formatFileSize, renderIcon };
