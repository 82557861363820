import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, shadows, mixins }) => ({
  root: {
    flex: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: ({ isHorizontal }) => (isHorizontal ? spacing(1, 2, 1.5) : spacing(1.5, 2, 1)),
    margin: spacing(0, 0.5),
    border: '1px solid',
    borderColor: 'rgba(0,0,0,0.08)',
    boxShadow: mixins.boxShadows.primary.light,
  },
  cardButton: {
    '&:hover': {
      boxShadow: shadows[8],
    },
  },
  stat: ({ isHorizontal }) =>
    isHorizontal
      ? {
          order: 1,
        }
      : {
          order: 3,
          [breakpoints.up('md')]: {
            fontSize: '1.75rem',
          },
          [breakpoints.up('sm')]: {
            fontSize: '1.5rem',
          },
        },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    order: ({ isHorizontal }) => (isHorizontal ? 3 : 1),
    flexDirection: ({ isHorizontal }) => (isHorizontal ? 'row' : 'column'),
    '& svg': {
      fontSize: ({ isHorizontal }) => (isHorizontal ? '2.4rem' : '2.7rem'),
      margin: ({ isHorizontal }) => (isHorizontal ? spacing(0, 1, 0, 0) : spacing(0, 0, 0.5)),
    },
  },
}));
