import React, { memo } from 'react';
import useStyles from './styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import numeral from 'numeral';
import CardWrapper from 'components/CardWrapper/CardWrapper';
import Transition from 'components/Transition/Transition';
import MediaCard from 'components/MediaCard/MediaCard';
import CardContent from '@material-ui/core/CardContent';
import StatCard from 'components/StatCard/StatCard';
import TvIcon from '@material-ui/icons/Tv';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';

function PaidAdCard(props) {
  const {
    requestCard,
    adTitle,
    campaign,
    mediaUrl,
    brand, //
    endDate,
    numOfScreens,
    budgetAllocated, //
    address, // branch address
    duration,
    mediaType,
    id,
    closeButton,
  } = props;
  const { province, area, city } = address || {};

  const classes = useStyles();

  const stats = [
    ...(requestCard
      ? [
          {
            title: 'Selected branch',
            icon: <StorefrontRoundedIcon />,
            stat: `${area ? area : province}, ${city}`,
          },
        ]
      : []),
    {
      title: requestCard ? 'Requested Screens' : 'Screens',
      icon: <TvIcon />,
      stat: numeral(numOfScreens).format('0,0'),
    },
    {
      title: 'End date',
      icon: <EventOutlinedIcon />,
      stat: moment(endDate).format('D/M/YYYY'),
    },
    {
      title: 'Duration',
      icon: <HourglassEmptyRoundedIcon />,
      stat: `${duration} seconds`,
    },
    {
      title: 'Allocated budget',
      icon: <MoneyOutlinedIcon />,
      stat: `${numeral(budgetAllocated).format('0,0')} EGP`,
    },
  ];
  const cardActions = closeButton ? [closeButton] : [];
  const extraActions = null;

  return (
    <Transition variant="grow" in={!!id} timeout={!!closeButton ? 0 : 800}>
      <CardWrapper
        title={`${adTitle} - ${campaign}`}
        subheader={brand && `By ${brand}`}
        chips={[{ label: `Paid Ad`, icon: <MonetizationOnRoundedIcon /> }]}
        defaultExpanded
        cardActions={cardActions}
        extraActions={extraActions}
        disabled={!!closeButton}
      >
        <CardContent className={classes.cardContent}>
          <Grid container spacing={1}>
            {stats.map(({ title, icon, stat }) => (
              <Grid item xs={6} sm={4} md={stats.length < 5 ? 3 : 4} key={title}>
                <StatCard title={title} icon={icon} stat={stat} />
              </Grid>
            ))}
          </Grid>
        </CardContent>

        <MediaCard
          url={mediaUrl}
          className={classes.cardMedia}
          title={adTitle}
          mediaType={mediaType}
          onClick={() => window.open(mediaUrl, '_blank')}
        />
      </CardWrapper>
    </Transition>
  );
}

export default memo(PaidAdCard);
