import React, { memo } from 'react';
import useStyles from './styles';
import MuiStepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Collapse from '@material-ui/core/Collapse';

function Stepper({ activeStep, steps }) {
  const classes = useStyles();

  return (
    <Collapse in={activeStep < steps.length} timeout={400}>
      <MuiStepper className={classes.root} activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </Collapse>
  );
}

Stepper.defaultProps = {
  activeStep: 0,
};

export default memo(Stepper);
