import React, { useEffect, useState, useCallback } from 'react';
import PageTransition from 'components/PageTransition/PageTransition';
import { loadAds } from 'redux/features/ads.slice';
import { useDispatch, useSelector } from 'react-redux';
import MyAdsCard from 'components/MyAdsCard/MyAdsCard';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { loadAdCreation } from 'redux/features/adCreation.slice';
import DashedButton from 'components/DashedButton/DashedButton';
import useLazyLoading from 'utils/hooks/useLazyLoading';
import AddIcon from '@material-ui/icons/Add';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import SearchFilter from 'components/SearchFilter/SearchFilter';
import useStyles from './styles';

function Dashbord() {
  const dispatch = useDispatch();
  const ads = useSelector(state => state.ads);
  const classes = useStyles();
  const { lastElementRef, itemsCount } = useLazyLoading({
    loading: ads.loading,
    initialItemsCount: 2,
    increaseBy: 1,
    listLength: ads.list?.length,
  });
  const up600 = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    dispatch(loadAds());
  }, [dispatch]);

  process.env.NODE_ENV === 'development' && console.log(ads);

  const [search, setSearch] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);

  const filterByQuery = (filters, query) =>
    filters.some(val => val.toLowerCase().includes(query.toLowerCase()));

  const filterCallBack = useCallback(
    ({ adTitle, campaign }) => {
      if (!search) return true;
      return filterByQuery([adTitle, campaign], search);
    },
    [search],
  );

  const handleCreateAd = () => dispatch(loadAdCreation());

  return (
    <PageTransition>
      <div className={classes.pageHeader}>
        <h1 className={classes.pageTitle}>Your ads</h1>
        <Button
          className={classes.createAdButton}
          size={up600 ? 'large' : 'medium'}
          variant="contained"
          endIcon={<AddIcon />}
          onClick={handleCreateAd}
        >
          Create Ad
        </Button>
        <div className={classes.search}>
          <SearchFilter
            value={search}
            setValue={setSearch}
            onChange={event => setSearch(event.target.value)}
            tooltipTitle="Search ads by ad title or campaign name"
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
          />
        </div>
      </div>
      <LoadingSpinner in={ads.loading} mountOnEnter unmountOnExit timeout={400} />
      <Collapse in={!ads.loading} mountOnEnter unmountOnExit timeout={400}>
        <div className={classes.list}>
          {ads.list.length ? (
            ads.list
              .filter(filterCallBack)
              .slice(0, itemsCount)
              .map((ad, i, arr) => (
                <div
                  key={ad.adTitle}
                  className={classes.listItem}
                  ref={i === arr.length - 1 ? lastElementRef : null}
                >
                  <MyAdsCard {...ad} />
                </div>
              ))
          ) : (
            <DashedButton
              label="Create your first ad"
              icon={<AddCircleOutlineIcon />}
              onClick={handleCreateAd}
            />
          )}
        </div>
      </Collapse>
    </PageTransition>
  );
}

export default Dashbord;
