import React, { useState, useEffect } from 'react';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './styles';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from 'components/Login/Login';
import { AdzilyLogo } from 'assets/svg-components';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { advertiserLink } from 'config/constants';
import ErrorBoundary from 'utils/ErrorBoundary';
import MouseParallax from 'components/MouseParallax/MouseParallax';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';

function AuthPage() {
  const [showLogin, setShowLogin] = useState(false);
  const { currentUser } = useSelector(state => state.auth);
  const classes = useStyles({ showLogin });
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });

  useEffect(() => {
    up600 && setShowLogin(true);
  }, [up600]);

  const loginFormHeader = (
    <>
      <Typography variant="h4" gutterBottom>
        Sign in
      </Typography>
    </>
  );

  const loginAs = (
    <Link
      variant="body1"
      className={classes.loginAs}
      target="_blank"
      rel="noopener noreferrer"
      href={advertiserLink}
      underline="always"
    >
      Log in as advertiser
    </Link>
  );

  if (currentUser) {
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className={classes.background} />
      <div className={classes.root}>
        <Fade in={up600 || !showLogin} mountOnEnter unmountOnExit timeout={1500}>
          <Box height="100%" width="100%">
            <MouseParallax />
          </Box>
        </Fade>

        <div className={classes.container}>
          {(showLogin || !up600) && (
            <AdzilyLogo
              className={`${classes.logo} ${!showLogin && !up600 ? classes.fixed : ''}`}
            />
          )}
          <div>
            <ErrorBoundary>
              <Collapse in={showLogin} mountOnEnter unmountOnExit timeout={600}>
                <Login formHeader={loginFormHeader} />
                {loginAs}
              </Collapse>
            </ErrorBoundary>
          </div>
          {!up600 && !showLogin && (
            <Button
              variant="outlined"
              className={`${classes.loginButton} ${!up600 ? classes.fixed : ''}`}
              onClick={() => setShowLogin(true)}
              size="large"
            >
              Login
            </Button>
          )}
        </div>
      </div>
    </>
  );
}

export default AuthPage;
