import { makeStyles } from '@material-ui/core/styles';
import backgroundUrl from 'assets/images/bg.jpg';

export default makeStyles(({ spacing, breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      alignItems: 'flex-end',
    },
  },
  background: {
    backgroundImage: `url(${backgroundUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: -4,
  },
  container: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(4),
    [breakpoints.up('sm')]: {
      minHeight: '100vh',
      maxWidth: '50%',
    },
    [breakpoints.up('md')]: {
      maxWidth: 'unset',
      marginRight: '3%',
    },
    [breakpoints.up('lg')]: {
      marginRight: '12%',
    },
  },
  logo: {
    width: '1em',
    height: 'auto',
    color: '#fff',
    fontSize: '18rem',
    margin: '0 auto 40px',
    top: '5%',
  },
  fixed: {
    position: 'fixed',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
  },
  loginButton: {
    left: '50%',
    transform: 'translateX(-50%)',
    color: '#fff',
    borderColor: '#fff',
    bottom: '6%',
  },
  link: {
    cursor: 'pointer',
  },
  loginAs: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: spacing(2),
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 500,
  },
}));
