import authReducer from './auth.slice';
import branchesReducer from './branches.slice';
import branchAdsListReducer from './branchAdsList.slice';
import adCreationReducer from './adCreation.slice';
import adsReducer from './ads.slice';

const rootReducer = {
  auth: authReducer,
  branches: branchesReducer,
  branchAdsList: branchAdsListReducer,
  adCreation: adCreationReducer,
  ads: adsReducer,
};

export default rootReducer;
