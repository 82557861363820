import { createAction, createReducer } from '@reduxjs/toolkit';

export const loadAds = createAction('ads/loadAds');
export const receivedAds = createAction('ads/receivedAds');
export const deleteAd = createAction('ads/deleteAd');
export const deleteAdSuccess = createAction('ads/deleteAdSuccess');

const initialState = {
  loading: true,
  list: [],
  count: 0,
  page: 1,
};

export default createReducer(initialState, {
  [receivedAds]: (state, action) => {
    const { list, count } = action.payload;
    return {
      ...state,
      loading: false,
      list,
      count,
    };
  },
  [deleteAdSuccess]: (state, action) => {
    const id = action.payload;
    return {
      ...state,
      list: state.list.filter(ad => ad.id !== id),
      count: state.count - 1,
    };
  },
});
