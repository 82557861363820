import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import paths from 'routes/paths';

function Tabs({ badgeContent }) {
  const classes = useStyles();
  const history = useHistory();
  const navPath = paths.find(route => route.path === history.location.pathname)?.path || '/';
  const [value, setValue] = useState(navPath);

  useEffect(() => {
    if (navPath === value) return;
    setValue(navPath);
  }, [navPath, value]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <MuiTabs
      indicatorColor="secondary"
      value={value}
      onChange={handleTabChange}
      aria-label="tabs"
      className={classes.tabs}
    >
      {paths.map(({ label, path }, i) => (
        <Tab
          key={i}
          className={classes.tab}
          disableRipple
          label={
            path === '/requests' ? (
              <Badge badgeContent={badgeContent} color="primary">
                <span className="MuiTab-wrapper">{label}</span>
              </Badge>
            ) : (
              label
            )
          }
          value={path}
        />
      ))}
    </MuiTabs>
  );
}

export default Tabs;
