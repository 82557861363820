/**
 * From here, you can change every single style injected by Material-UI in an Mui component
 * https://material-ui.com/customization/globals/#css
 */
import mixins from './mixins.theme';

export default {
  // Style sheet name ⚛️
  MuiButton: {
    // Name of the rule
    label: {
      // Some CSS
      // textTransform: 'none',
    },
  },
  MuiSkeleton: {
    root: {
      animation: '$pulse 1.5s ease-in-out 0.3s infinite',
    },
    ...mixins.keyframes.pulse,
  },
};
