import React, { memo } from 'react';
import useStyles from './styles';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CountUp from 'react-countup';
import clsx from 'clsx';

function StatCard({
  title,
  icon,
  count,
  stat,
  duration,
  currency,
  onClick,
  variant,
  className,
  extra,
}) {
  const isHorizontal = variant === 'horizontal';
  const classes = useStyles({ isHorizontal });

  const renderCount = ({ start, end }) => (
    <CountUp duration={duration} start={start} end={end} separator="," />
  );

  const BtnWrapper = childs =>
    onClick ? (
      <ButtonBase style={{ width: '100%' }} onClick={onClick}>
        {childs}
      </ButtonBase>
    ) : (
      childs
    );

  return BtnWrapper(
    <Card className={clsx(classes.root, !!onClick && classes.cardButton, className)}>
      <div className={classes.titleContainer}>
        {icon}
        <Typography variant="body2" align="center">
          {title}
        </Typography>
      </div>
      {extra}
      <Typography variant="h6" align="center" className={classes.stat}>
        {count ? renderCount(count) : stat}
        {currency && ` ${currency}`}
      </Typography>
    </Card>,
  );
}

StatCard.defaultProps = {
  duration: 2,
  variant: 'vertical',
};

export default memo(StatCard);
