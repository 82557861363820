import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    [breakpoints.down('xs')]: {
      marginTop: spacing(1.5),
    },
    '& .MuiButton-label': {
      color: '#fff',
    },
    '& .Mui-disabled .MuiButton-label': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
  buttonLabel: {
    fontWeight: 'inherit',
    fontSize: 'inherit',
    maxWidth: 150,
  },
}));
