import React, { memo, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import useStyles from './styles';

function PageTransition({ children }, more) {
	const [ transition, setTransition ] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		setTransition(true);
	}, []);

	return (
		<CSSTransition {...more} in={transition} timeout={450} unmountOnExit classNames="page">
			<div className={classes.page}>{children}</div>
		</CSSTransition>
	);
}

export default memo(PageTransition);
