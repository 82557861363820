import React, { memo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import Grow from '@material-ui/core/Grow';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Stepper from 'components/Stepper/Stepper';
import DialogTitle from 'components/DialogTitle/DialogTitle';
import AdCampaign from 'components/AdCampaign/AdCampaign';
import PeopleIcon from '@material-ui/icons/People';
import LoopIcon from '@material-ui/icons/Loop';
import TvIcon from '@material-ui/icons/Tv';
import AdCreationStat from 'components/AdCreationStat/AdCreationStat';
import { useSelector, useDispatch } from 'react-redux';
import { submitAd, setDialogOpen, formFields, reset } from 'redux/features/adCreation.slice';
import UploadSnackbar from 'components/UploadSnackbar/UploadSnackbar';
import { useSnackbar } from 'notistack';
import WarningDialog from 'components/WarningDialog/WarningDialog';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const steps = ['Ad Info', 'Ad Placement'];

const statsList = [
  {
    item: 'screens',
    title: 'Screens',
    miniTitle: 'Screens',
    icon: <TvIcon />,
  },
  {
    item: 'visitors',
    title: 'Estimated visitors',
    miniTitle: 'Visitors',
    icon: <PeopleIcon />,
  },
  {
    item: 'adLoops',
    title: 'Estimated ad loops',
    miniTitle: 'Estimated ad loops',
    icon: <LoopIcon />,
  },
];

function CreateAdDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });
  const up900 = useMediaQuery('(min-width:900px)', { noSsr: true });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const adCreation = useSelector(state => state.adCreation);
  const { attachedFiles } = adCreation.fileUpload;
  const { fields, validation } = adCreation.form;
  const dialogOpen = adCreation.dialogOpen;

  const [activeStep, setActiveStep] = useState(0);
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);

  const handleClose = () => {
    setWarningDialogOpen(false);
    dispatch(setDialogOpen(false));
    dispatch(reset());
    setActiveStep(0);
  };

  const openSnackbar = () => {
    const key = 'upload-progress';
    const snackbarOptions = {
      persist: true,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      key,
      content: <UploadSnackbar attachedFilesArr={attachedFiles} id={key} />,
    };
    enqueueSnackbar('', snackbarOptions);
  };

  const handleSubmit = () => {
    dispatch(submitAd({ openSnackbar, closeSnackbar }));
    setActiveStep(0);
  };

  const isLastStep = activeStep === steps.length - 1;
  const noScreensSelected =
    activeStep > 0 &&
    !adCreation.branches?.some(item => item.screensCollection?.some(screen => screen?.selected));
  const isNextDisabled =
    !attachedFiles.length ||
    formFields.some(f => !!validation?.[f?.item]?.length || !fields?.[f?.item]?.length) ||
    noScreensSelected;

  return (
    <>
      <WarningDialog
        open={warningDialogOpen}
        onClose={() => setWarningDialogOpen(false)}
        onProceed={handleClose}
        title={`Cancel ad ${adCreation.adID ? 'editing' : 'creation'}?`}
        description="Are you sure you want to cancel? Your progress will be lost."
        closeLabel="No"
        proceedLabel="Yes"
      />
      <Dialog
        TransitionComponent={Transition}
        TransitionProps={{
          mountOnEnter: true,
          unmountOnExit: true,
        }}
        open={dialogOpen}
        onClose={() => setWarningDialogOpen(true)}
        aria-labelledby="form-dialog-title"
        fullScreen={!up600}
        fullWidth
        maxWidth="md"
        classes={{ paper: classes.root }}
      >
        <DialogTitle onClose={() => setWarningDialogOpen(true)}>Create Ad</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Stepper activeStep={activeStep} steps={steps} />
          <AdCampaign activeStep={activeStep} steps={steps} />
        </DialogContent>
        {!up900 && activeStep > 0 && (
          <div className={classes.stats}>
            {statsList.map(stat => (
              <AdCreationStat key={stat.item} count={adCreation.stats[stat.item]} {...stat} />
            ))}
          </div>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button
            disabled={activeStep < 1}
            onClick={() => setActiveStep(st => st - 1)}
            className={classes.backButton}
            size="large"
            color="primary"
          >
            Back
          </Button>
          {up900 && activeStep > 0 && (
            <div className={classes.stats}>
              {statsList.map(stat => (
                <AdCreationStat key={stat.item} count={adCreation.stats[stat.item]} {...stat} />
              ))}
            </div>
          )}

          <Button
            disabled={isNextDisabled}
            onClick={() => {
              isLastStep ? handleSubmit() : setActiveStep(st => st + 1);
            }}
            variant="contained"
            color="primary"
          >
            {isLastStep ? 'Run Ad' : 'Next'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(CreateAdDialog);
