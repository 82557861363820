import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette, breakpoints }) => ({
  popover: {
    padding: spacing(2.1, 1),
    backgroundColor: palette.grey[900],
    [breakpoints.up('sm')]: {
      padding: spacing(2),
    },
  },
  popoverContent: {
    padding: spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      padding: spacing(0, 1.5),
    },
  },
  signOutButton: {
    marginTop: spacing(2),
  },
  bolder: {
    fontWeight: 500,
  },
  name: {
    color: '#fff',
    lineHeight: 1.66,
  },
  email: {
    color: '#fff',
  },
}));
