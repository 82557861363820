import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, palette }) => ({
  root: {
    width: '100%',
    padding: spacing(3, 2),
    '& div': {
      padding: spacing(3),
      borderRadius: 2,
      border: '1px solid',
      borderColor: palette.divider,
      '& .MuiTypography-root': {
        textAlign: 'center',
      },
    },
  },
}));
