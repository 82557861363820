import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, shadows, shape, palette, breakpoints, mixins }) => ({
  root: {
    boxShadow: shadows[1],
    borderRadius: shape.borderRadius,
  },
  expPanel: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 'auto',
    },
  },
  expPanelSummary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    minHeight: 56,
    borderBottom: 'none',
    '&.Mui-expanded': {
      minHeight: spacing(8, 0),
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    '& .MuiExpansionPanelSummary-content': {
      alignItems: 'center',
      margin: spacing(1.5, 0),
      [breakpoints.up('sm')]: {
        margin: spacing(2, 0),
      },
    },
    '& p': {
      fontWeight: 500,
    },
  },
  expPanelDetails: {
    padding: 0,
    display: 'block',
  },
  area: {
    backgroundColor: 'rgba(0, 0, 0, .04)',
  },
  icon: {
    minWidth: 'unset !important',
    marginRight: spacing(1),
    '& > span': {
      color: palette.text.primary,
      fontSize: '1.9rem',
      [breakpoints.up('sm')]: {
        fontSize: '2.2rem',
      },
      [breakpoints.up('md')]: {
        marginRight: spacing(1.5),
        fontSize: '2.4rem',
      },
    },
  },
  list: {
    padding: spacing(1, 0),
    overflowY: 'auto',
    maxHeight: 240,
    ...mixins.scrollbar,
  },
}));
