import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, palette }) => ({
  root: {
    backgroundColor: palette.background.default,
  },
  dialogContent: {
    padding: spacing(1),
    [breakpoints.up('sm')]: {
      padding: spacing(1, 3),
    },
  },
  dialogActions: {
    padding: spacing(1, 2, 1.5),
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    [breakpoints.up('md')]: {
      marginBottom: 0,
      padding: '12px 5% 16px',
    },
  },
  backButton: {
    '&&': {
      marginRight: spacing(2),
    },
  },
  stats: {
    display: 'flex',
    justifyContent: 'center',
    padding: spacing(1, 1, 0),
    [breakpoints.up('md')]: {
      padding: 0,
    },
  },
}));
