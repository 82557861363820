import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints }) => ({
  cardContent: {
    order: 1,
    flex: 1,
    padding: spacing(1, 0.5),
    [breakpoints.up('sm')]: {
      padding: spacing(1, 1.375, 1, 0.5),
    },
    '& > div': {
      height: '100%',
    },
  },
  cardMedia: {
    height: 'auto',
    minHeight: 130,
    minWidth: 100,
    order: 0,
    margin: spacing(0, 1, 1),
    [breakpoints.up('sm')]: {
      width: '100%',
      margin: spacing(1),
      maxWidth: 150,
      marginLeft: 'auto',
      order: 2,
    },
  },
}));
