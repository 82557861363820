import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
import useStyles from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { formatFileName, formatFileSize, renderIcon } from 'utils/helpers/formatFile';

function FileChip({ file, handleDelete }) {
  const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });
  const classes = useStyles();

  const chipProps = {
    icon: <Icon className="chipIcon">{renderIcon(file.type)}</Icon>,
    classes: { icon: classes.chipIcon, label: classes.chipLabel },
    label: (
      <>
        <span>{formatFileName(file.name, up600 ? 28 : 22)}</span>
        <span className={classes.fileSize}>{formatFileSize(file.size)}</span>
      </>
    ),
    ...(file.downloadURL
      ? {
          clickable: true,
          component: 'a',
          href: file.downloadURL,
          target: '_blank',
          rel: 'noopener',
          onDelete: handleDelete,
        }
      : {
          onDelete: handleDelete,
        }),
  };
  const toolTipTitle = file.updated
    ? `Uploaded on ${moment(file.updated).format('MMMM Do YYYY, h:mm:ss a')}`
    : '';

  return (
    <Tooltip
      color="primary"
      placement="top"
      arrow
      title={toolTipTitle}
      classes={{
        tooltipArrow: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <Grow in timeout={600}>
        <Chip {...chipProps} />
      </Grow>
    </Tooltip>
  );
}

FileChip.propTypes = {
  file: PropTypes.object.isRequired,
  handleDelete: PropTypes.func,
};

export default memo(FileChip);
