import React, { memo } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useStyles from './styles';

const CardWrapper = React.forwardRef(
  (
    {
      title,
      subheader,
      chips,
      cardActions,
      extraActions,
      children,
      defaultExpanded,
      onClick,
      disabled,
      keepExpanded,
    },
    ref,
  ) => {
    const classes = useStyles({ disabled });
    const up600 = useMediaQuery('(min-width:600px)', { noSsr: true });

    return (
      <Card ref={ref}>
        <Accordion
          defaultExpanded={defaultExpanded}
          disabled={disabled}
          className={classes.expPanel}
          {...(keepExpanded && { expanded: true })}
        >
          <AccordionSummary
            onClick={onClick}
            expandIcon={
              <Tooltip title="Expand" placement="top" arrow>
                <ExpandMoreIcon
                  style={{ opacity: disabled ? 0 : 1 }}
                  className={classes.expandIcon}
                />
              </Tooltip>
            }
            className={classes.expPanelSummary}
          >
            <CardHeader className={classes.cardHeader} title={title} subheader={subheader} />
            {chips && (
              <div className={classes.headerChips}>
                {chips?.map(
                  ({
                    label,
                    icon,
                    color = 'primary',
                    variant = 'outlined',
                    size = 'small',
                    className,
                    ...more
                  }) =>
                    !up600 ? (
                      icon
                    ) : (
                      <Chip
                        className={clsx(classes.chip, className)}
                        key={label}
                        label={label}
                        icon={icon}
                        color={color}
                        variant={variant}
                        size={size}
                        {...more}
                      />
                    ),
                )}
              </div>
            )}

            <CardActions disableSpacing className={classes.cardActions}>
              {extraActions}
              {cardActions?.map(({ title, icon, onClick, size } = {}) => (
                <Tooltip key={title} title={title} placement="top" arrow>
                  <IconButton
                    aria-label={title}
                    onClick={e => {
                      e.stopPropagation();
                      onClick();
                    }}
                    size={size ?? 'medium'}
                  >
                    {icon}
                  </IconButton>
                </Tooltip>
              ))}
            </CardActions>
          </AccordionSummary>
          <AccordionDetails className={classes.expPanelDetails}>{children}</AccordionDetails>
        </Accordion>
      </Card>
    );
  },
);

export default memo(CardWrapper);
