import React, { useState, useEffect, memo } from 'react';
import TextField from '@material-ui/core/TextField';
import useStyles from './styles';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import nestArrayOfObjects from 'utils/helpers/nestArrayOfObjects';
import FileUpload from 'components/FileUpload/FileUpload';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { setFiles, setForm, formFields } from 'redux/features/adCreation.slice';

const validate = (name, val, { fields, campaigns }) => {
  const value = val?.trim();
  switch (name) {
    case 'campaignName':
      return value.length < 1 ? 'Enter your campaign name' : '';
    case 'adName':
      return value.length < 1
        ? 'Enter your ad name'
        : campaigns
            .filter(item => item.campaign === fields.campaignName)?.[0]
            ?.adTitle?.some(item => item.adTitle === value)
        ? 'Ad name must be unique for each campaign'
        : '';
    case 'runFor':
      return value.length < 1 ? 'Enter how many weeks should the ad run' : '';
    case 'adLength':
      return value.length < 1 ? 'Select ad length' : '';
    default:
      break;
  }
};

const filter = createFilterOptions();

function AdCreationForm() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const adCreation = useSelector(state => state.adCreation);
  const [fields, setFields] = useState(adCreation.form.fields);
  const [validation, setValidation] = useState(adCreation.form.validation);
  const [adStartDate, setAdStartDate] = useState(adCreation.form.adStartDate);
  const [attachedFiles, setAttachedFiles] = useState(adCreation.fileUpload.attachedFiles);

  const ads = useSelector(state => state.ads);
  const campaigns = nestArrayOfObjects(
    ads.list.map(({ adTitle, campaign }) => ({ adTitle, campaign })),
    'campaign',
    'adTitle',
  );

  const handleChange = evt => {
    const { name, value } = evt.target;
    setFields(state => ({ ...state, [name]: value }));

    !validate(name, value, { fields, campaigns }) && handleValidation(evt);
  };

  const handleValidation = evt => {
    const { name, value } = evt.target;
    setValidation(state => ({ ...state, [name]: validate(name, value, { fields, campaigns }) }));
  };

  const handleDateChange = date => {
    setAdStartDate(date);
  };

  useEffect(() => {
    dispatch(setForm({ fields, validation, adStartDate }));
  }, [dispatch, fields, validation, adStartDate]);

  useEffect(() => {
    dispatch(setFiles(attachedFiles));
  }, [dispatch, attachedFiles]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form autoComplete="off">
        <Autocomplete
          value={fields.campaignName}
          onChange={(event, newValue) => {
            // Create a new value from the user input
            if (newValue && newValue.inputValue) {
              setFields(state => ({ ...state, campaignName: newValue?.inputValue }));
            } else {
              setFields(state => ({ ...state, campaignName: newValue?.campaign || newValue }));
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                campaign: `Add new campaign? "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="campaign-name"
          options={campaigns}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.campaign;
          }}
          renderOption={option => option.campaign}
          freeSolo
          onBlur={handleValidation}
          renderInput={params => (
            <TextField
              {...params}
              error={validation.campaignName.length > 0}
              helperText={validation.campaignName}
              name="campaignName"
              margin="normal"
              label={'Campaign Name'}
            />
          )}
        />

        {formFields.slice(1).map((f, i) => (
          <TextField
            key={i}
            margin="normal"
            id={f.item}
            value={fields[f.item]}
            onChange={handleChange}
            onBlur={handleValidation}
            className={clsx(classes.textField, classes[f.item])}
            label={f.label}
            name={f.item}
            fullWidth
            error={validation[f.item].length > 0}
            helperText={validation[f.item]}
            {...(f.type === 'select'
              ? {
                  select: true,
                  children: f.selectItems.map(selectItem => (
                    <MenuItem key={selectItem.value} value={selectItem.value}>
                      {selectItem.label}
                    </MenuItem>
                  )),
                }
              : {
                  type: f.type,
                })}
          />
        ))}

        <DatePicker
          className={classes.datePicker}
          showTodayButton
          margin="normal"
          variant="dialog"
          openTo="month"
          format="dd/MM/yyyy"
          views={['date']}
          label="Ad Start Date"
          value={adStartDate}
          onChange={handleDateChange}
          autoOk
          fullWidth
          disablePast
        />
        <FileUpload attachedFiles={attachedFiles} setAttachedFiles={setAttachedFiles} />
      </form>
    </MuiPickersUtilsProvider>
  );
}

export default memo(AdCreationForm);
