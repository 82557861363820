import React, { useEffect, useState } from 'react';
import PageTransition from 'components/PageTransition/PageTransition';
import { loadBranches } from 'redux/features/branches.slice';
import { useDispatch, useSelector } from 'react-redux';
import BranchCard from 'components/BranchCard/BranchCard';
import useStyles from './styles';
import useLazyLoading from 'utils/hooks/useLazyLoading';
import EmptyBox from 'components/EmptyBox/EmptyBox';
import Collapse from '@material-ui/core/Collapse';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import FilterBy from 'components/FilterBy/FilterBy';
import useRouteQuery from 'utils/hooks/useRouteQuery';
import { useHistory } from 'react-router-dom';

function Dashbord() {
  const dispatch = useDispatch();
  const branches = useSelector(state => state.branches);
  const classes = useStyles();
  const history = useHistory();
  const { lastElementRef, itemsCount } = useLazyLoading({
    loading: branches.loading,
    initialItemsCount: 4,
    increaseBy: 1,
    listLength: branches.list?.length,
  });
  const showAll = 'Show all';
  const branchesNamesList = [...new Set(branches?.list?.map(br => br.brand.toLowerCase()))];
  const selectedBrand = useRouteQuery('brand');
  const [filteredBrand, setFilteredBrand] = useState(
    branchesNamesList?.includes(selectedBrand) ? selectedBrand : showAll,
  );

  useEffect(() => {
    setFilteredBrand(branchesNamesList?.includes(selectedBrand) ? selectedBrand : showAll);
  }, [branchesNamesList, selectedBrand]);

  useEffect(() => {
    dispatch(loadBranches());
  }, [dispatch]);

  process.env.NODE_ENV === 'development' && console.log(branches);
  const filterOptions = [showAll, ...branchesNamesList];

  return (
    <PageTransition>
      <div className={classes.flex}>
        <h1 className={classes.pageTitle}>Your branches</h1>
      </div>
      <LoadingSpinner in={branches.loading} mountOnEnter unmountOnExit timeout={400} />
      <Collapse in={!branches.loading} mountOnEnter unmountOnExit timeout={400}>
        <>
          <FilterBy
            options={filterOptions}
            selection={filteredBrand}
            buttonLabel={filteredBrand === showAll ? 'Filter by brand' : filteredBrand}
            onSelection={option => {
              setFilteredBrand(option);
              history.push(option === showAll ? '/' : `/?brand=${option.replace(/ /g, '-')}`);
            }}
          />

          <div className={classes.list}>
            {branches?.list
              ?.filter(br =>
                filteredBrand !== showAll
                  ? br.brand.toLowerCase() === filteredBrand
                  : filteredBrand,
              )
              .slice(0, itemsCount)
              .map((branch, i, arr) => (
                <div
                  key={branch.id}
                  className={classes.listItem}
                  ref={i === arr.length - 1 ? lastElementRef : null}
                >
                  <BranchCard {...branch} />
                </div>
              ))}
            {!branches?.list?.length && <EmptyBox label="You don't have any branches." />}
          </div>
        </>
      </Collapse>
    </PageTransition>
  );
}

export default Dashbord;
