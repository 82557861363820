import React from 'react';
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import Collapse from '@material-ui/core/Collapse';

function Transition({ children, variant, direction, ...props }) {
  let Component;
  switch (variant) {
    case 'slide':
      Component = Slide;
      break;
    case 'grow':
      Component = Grow;
      break;
    case 'fade':
      Component = Fade;
      break;
    case 'collapse':
      Component = Collapse;
      break;

    default:
      break;
  }

  return (
    <Component {...props}>
      <div>{children}</div>
    </Component>
  );
}

Transition.defaultProps = {
  variant: 'slide',
  direction: 'left',
};

export default Transition;
