import { put, call, takeLatest } from 'redux-saga/effects';
import {
  loadBranchAds,
  receivedAds,
  loadScreens,
  receivedScreens,
} from 'redux/features/branchAdsList.slice';
import snackbar from 'utils/snackbar';
import API from 'utils/api';

const fetchBranch = branchID => API.get(`/api/branches/ads/${branchID}`);
const fetchScreens = branchID => API.get(`/api/branches/screens/${branchID}`);

function* handleLoadBranch(action) {
  try {
    const branchID = yield action.payload;
    const resp = yield call(fetchBranch, branchID);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    const { adsList, myAdsList, ...branchInfo } = yield resp.data[0];
    yield put(receivedAds({ branchInfo, adsList, myAdsList }));
  } catch (error) {
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('We were unable to fetch your ads');
  }
}

function* handleLoadScreens(action) {
  try {
    const branchID = yield action.payload;
    const resp = yield call(fetchScreens, branchID);
    yield process.env.NODE_ENV === 'development' && console.log(resp);
    const { screensCollection, ...branchInfo } = yield resp.data;
    yield put(receivedScreens({ branchInfo, screensCollection }));
  } catch (error) {
    yield console.log(error.response);
    yield console.log(error.toString());
    yield snackbar.error('We were unable to fetch your screens');
  }
}

export default function* watchBranch() {
  yield takeLatest(loadBranchAds.toString(), handleLoadBranch);
  yield takeLatest(loadScreens.toString(), handleLoadScreens);
}
