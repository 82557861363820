import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import CodeRoundedIcon from '@material-ui/icons/CodeRounded';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import MovieOutlinedIcon from '@material-ui/icons/MovieOutlined';
import clsx from 'clsx';
import useStyles from './styles';

function MediaCard({ url, title, mediaType, className, onClick, showTitle }) {
  const classes = useStyles({ showTitle });
  const component = mediaType === 'video' ? 'video' : mediaType === 'image' ? 'img' : 'div';

  return (
    <ButtonBase className={clsx(classes.root, className)} onClick={onClick}>
      <CardMedia className={classes.media} src={url} title={title} component={component} />
      <div className={`${classes.overlay} ${component !== 'div' ? classes.hover : ''}`}>
        <div>
          {mediaType === 'video' ? (
            <MovieOutlinedIcon />
          ) : mediaType === 'image' ? (
            <ImageOutlinedIcon />
          ) : (
            <CodeRoundedIcon />
          )}

          {showTitle && (
            <Typography className={classes.typography} variant="body2">
              {title}
            </Typography>
          )}
        </div>
      </div>
    </ButtonBase>
  );
}

export default MediaCard;
