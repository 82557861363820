import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, shadows, mixins, palette }) => ({
  root: {
    height: 42,
    display: 'inline-flex',
    backgroundColor: palette.background.light,
    '& .MuiIconButton-root': {
      color: '#fff',
      '&.Mui-disabled': {
        color: palette.action.disabledLight,
      },
    },
    boxShadow: mixins.boxShadows.primary.dark,
    '&:hover': {
      boxShadow: ({ searchOpen }) => (searchOpen ? shadows[2] : shadows[4]),
    },
    '&:active': {
      boxShadow: ({ searchOpen }) => (searchOpen ? shadows[2] : shadows[8]),
    },
  },
  searchButton: {
    backgroundColor: 'transparent',
    color: '#fff',
    minWidth: 48,
    '&.Mui-disabled': {
      color: palette.action.disabledLight,
    },
    '&:hover': {
      backgroundColor: palette.action.hoverLight,
    },
  },
  textField: {
    transition: 'max-width 0.5s ease',
    maxWidth: ({ searchOpen }) => (searchOpen ? '200px' : 0),
    width: '100%',
    overflow: 'hidden',
    '& .MuiInputBase-root': {
      overflow: 'hidden',
      color: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent !important',
    },
    '& input': {
      paddingLeft: 0,
      paddingTop: spacing(2),
      paddingBottom: spacing(0.6),
    },
    '& .MuiInputLabel-root': {
      color: 'rgba(255, 255, 255, 0.74)',
      fontSize: 'inherit',
      transform: 'translate(0px, 14px) scale(1)',
      whiteSpace: 'nowrap',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0px, 4px) scale(0.75)',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      height: '100%',
      paddingRight: 'unset',
      '& button': {
        position: 'relative',
        zIndex: 1,
        opacity: ({ searchOpen }) => (searchOpen ? 1 : 0),
      },
    },
  },
}));
