import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ spacing, breakpoints, mixins, palette }) => ({
  pageHeader: {
    margin: spacing(3, 0, 1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [breakpoints.up('sm')]: {
      margin: spacing(3, 0),
    },
  },
  pageTitle: {
    color: '#fff',
    margin: 0,
    marginRight: 'auto',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  createAdButton: {
    backgroundColor: palette.background.light,
    color: '#fff',
    order: 2,
    boxShadow: mixins.boxShadows.primary.dark,
    '&:hover': {
      backgroundColor: palette.action.hoverLight,
    },
  },
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    order: 3,
    marginTop: spacing(2),
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 'unset',
      margin: spacing(0, 2, 0, 0),
      order: 1,
    },
  },
  list: {
    margin: spacing(1, 0),
  },
  listItem: {
    margin: spacing(1, 0, 3),
  },
}));
