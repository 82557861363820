import { useState } from 'react';

function useForm(formFields, validate) {
  const getInitFields = validation =>
    formFields.reduce(
      (ac, f) => ({ ...ac, [f.item]: validation ? '' : f?.initialValue ?? '' }),
      {},
    );
  const [fields, setFields] = useState(getInitFields());
  const [validation, setValidation] = useState(getInitFields('validation'));

  const handleChange = evt => {
    const { name, value } = evt.target;
    setFields(state => ({ ...state, [name]: value }));

    !!validate && !validate(name, value, fields) && handleValidation(evt);
  };

  const handleValidation = evt => {
    const { name, value } = evt.target;
    !!validate && setValidation(state => ({ ...state, [name]: validate(name, value, fields) }));
  };

  const isSubmitDisabled = (arr = formFields) =>
    arr.some(f => !!validation?.[f?.item]?.length || !fields?.[f?.item]?.length);

  const resetFields = () => {
    setFields(getInitFields());
    setValidation(getInitFields('validation'));
  };

  return {
    fields,
    validation,
    handleChange,
    handleValidation,
    isSubmitDisabled,
    setValidation,
    resetFields,
    setFields,
  };
}

export default useForm;
