import React, { memo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@material-ui/core/Collapse';
import useStyles from './styles';

function LoadingSpinner({ color, size, thickness, ...transitionProps }) {
  const classes = useStyles({ color });

  return (
    <Collapse {...transitionProps}>
      <div className={classes.spinner}>
        <CircularProgress size={size} thickness={thickness} color="secondary" />
      </div>
    </Collapse>
  );
}

LoadingSpinner.defaultProps = {
  color: '#fff',
  size: 90,
  thickness: 3.6,
};

export default memo(LoadingSpinner);
